import urlB64ToUint8Array from "./urlB64ToUint8Array"

function updateSubscriptionOnServer({firestore, activeUser, pushSubscription}) {
    // Send subscription to Firestore
    if (pushSubscription) {
        const {uid} = activeUser
        firestore.collection("users").doc(uid).update({
          pushSubscription: JSON.stringify(pushSubscription),
        })
        .catch()
    } else {
        // No push subscription object
    }
}

export function SubscribeUserToPush({firestore, activeUser}) {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(function(reg) {

        if (Notification.permission === "granted") {
          const applicationServerPublicKey=process.env.REACT_APP_FCM_PUBLIC_KEY
          const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey)      
          reg.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: applicationServerKey
          }).then(function(pushSubscription) {
              updateSubscriptionOnServer({firestore, pushSubscription, activeUser});
          }).catch(function(e) {
              if (Notification.permission === 'denied') {
              console.warn('Permission for notifications was denied');
              } else {
              console.error('Unable to subscribe to push', e);
              }
          });
        } 
      })
    }
}

function UnsubscribeUserToPush({activeUser}) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(function(reg) {
      if (Notification.permission === "granted") {
        reg.pushManager.getSubscription().then(function(pushSubscription) {
          pushSubscription.unsubscribe().then(function(successful) {
            // You've successfully unsubscribed
          }).catch(function(e) {
            // Unsubscription failed
          })
        })     
      } 
    })
  }  
}

function updateSubscriptionPreference({notificationState, firestore, activeUser}) {
  if (notificationState) {
    // Notifications are allowed, subscribe the user to push notifications
    SubscribeUserToPush({firestore, activeUser})
  } else {
    // Notifications are allowed, but unsubscribe the user to push notifications
    // Within current implementation, this will never be called because the switch is disabled to reflect the lack of support for the Permissions.revoke() method and lack of flags in the user database.
    UnsubscribeUserToPush({activeUser})
  }
}

export default function SetNotificationsPermissions({activeUser, firestore, notificationState}) {
 
    // Let's check whether notification permissions have already been granted
    if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      updateSubscriptionPreference({notificationState, firestore, activeUser})
    }
  
    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          updateSubscriptionPreference({notificationState, firestore, activeUser})
        }
      });
    }
  
    // At last, if the user has denied notifications, and you 
    // want to be respectful there is no need to bother them any more.
  }