import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 175,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SelectPartner({choice, setChoice, options}) {
  const classes = useStyles();

  const handleChange = (event) => {
    setChoice(options.find(partner => {
      return partner.email === event.target.value;
    }))
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Partner</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={choice.email || ""}
          onChange={handleChange}
        >
          {options.map(partner => 
          <MenuItem key={partner.email} value={partner.email}>{partner.displayName}</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
}
