import React from 'react';
import PropTypes from 'prop-types';

import UpdateProfile from '../Components/UpdateProfile'
import UpdatePassword from '../Components/UpdatePassword'
// import UpdateAccount from '../Components/UpdateAccount'
import DeleteAccount from '../Components/DeleteAccount'

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import NotificationSwitch from '../Components/NotificationSwitch';
import { useTranslation } from 'react-i18next';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p:3}}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    marginTop:theme.spacing(6),
  }
}));

export default function ProfileRoute() {

  const {t} = useTranslation()
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs 
          value={value} 
          onChange={handleChange}
          aria-label="Profile"
        >
          <Tab label={t('Profile.user')} {...a11yProps(0)} />
          <Tab label={t('Profile.password')} {...a11yProps(1)} />
          <Tab label={t('Profile.account')} {...a11yProps(2)} />
          {!("Notification" in window) ? null : <Tab label={t('Profile.notifications')} {...a11yProps(3)} />}
        </Tabs>
      </AppBar>
      <div className={classes.center}>
        <TabPanel value={value} index={0}>
          <UpdateProfile/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UpdatePassword/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/* <UpdateAccount/> Subscription version: removed comments */}
          <DeleteAccount/>
        </TabPanel>
        {!("Notification" in window) ? null : 
          <TabPanel value={value} index={3}>
            <NotificationSwitch/>
          </TabPanel>
        }
      </div>
    </div>
  );
}