import React, { useContext } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import userContext from '../../Contexts/userContext'
import objectivesContext from '../../Contexts/objectivesContext'

import { Box, CircularProgress, FormControl, IconButton, InputLabel, makeStyles, MenuItem, Tooltip, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';

import { Select } from 'formik-material-ui';
import { TextField } from 'formik-material-ui';

import firebase from 'firebase/app'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles( (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
        minWidth: 300
    },
    formControl: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    left: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      width: '100%',
      marginLeft: theme.spacing(3)
    },
    dark: {
      color: theme.palette.error.main
    },
    self: {
      color: theme.palette.secondary.main,
    },
    borderedIcon: {
      borderColor: "#C0C0C0",
      borderStyle: 'solid',
      borderWidth: '1px',
      '&:hover': {
        borderColor: theme.palette.secondary.main,
      }
    },
  }));

export default function EditObjective({objId, objTitle, objDescription, objDuration, objPoints}) {

  const {t} = useTranslation()

  const [open, setOpen] = React.useState(false);

  const classes=useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const userState = useContext(userContext);
  const { activeUser } = userState;
  const {uid} = activeUser

  const objectivesState = useContext(objectivesContext);
  const {objectivesPartner} = objectivesState;

  const points = [1,2,3,4,5,6,7,8,9,10];
  const days = [
    {value:1, label:"1 "+t('General.day')},
    {value:2, label:"2 "+t('General.days')},
    {value:3, label:"3 "+t('General.days')},
    {value:4, label:"4 "+t('General.days')},
    {value:5, label:"5 "+t('General.days')},
    {value:6, label:"6 "+t('General.days')},
    {value:7, label:"7 "+t('General.days')},
    {value:8, label:"8 "+t('General.days')},
    {value:9, label:"9 "+t('General.days')},
    {value:10, label:"10 "+t('General.days')},
    {value:14, label:"2 "+t('General.weeks')},
    {value:21, label:"3 "+t('General.weeks')},
    {value:28, label:"4 "+t('General.weeks')}
  ];

  return (
    <div>
      <Tooltip title={t('General.edit')}><IconButton aria-label="edit" size="small" onClick={handleClickOpen} className={classes.borderedIcon}><EditIcon className={classes.self}/></IconButton></Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

        <DialogTitle id="form-dialog-title">{t('Objectives.edit')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span>{t('Objectives.editDialog')}</span>
          </DialogContentText>
          <Formik
            initialValues={{objDescriptionNew: objDescription, objPointsNew: objPoints, objDurationNew: objDuration, objTitleNew: objTitle}}
            
            validationSchema={Yup.object({
              objDescriptionNew: Yup.string()
              .max(250, t('Validation.max')+" 250 "+t('Validation.characters'))
              .required(t('Validation.required')),
              objTitleNew: Yup.string()
              .max(50, t('Validation.max')+" 50 "+t('Validation.characters'))
              .required(t('Validation.required')),
              objPointsNew: Yup.number().positive().integer()
              .min(1).max(10)
              .required(t('Validation.required')),
              objDurationNew: Yup.number().positive().integer()
              .min(1).max(28)
              .required(t('Validation.required')),
            })}
            
            onSubmit = { (values, {setSubmitting}) => {
              firebase.firestore().collection('users').doc(uid).collection('partners').doc(objectivesPartner.email).collection('objectives').doc(objId).update({
                objTitle: values.objTitleNew,
                objDescription: values.objDescriptionNew,
                objPoints: parseInt(values.objPointsNew),
                objDuration: parseInt(values.objDurationNew),
                objCreated: firebase.firestore.FieldValue.serverTimestamp(),
                objAchieved: "eval",
                objStatus: "pending",
                objComments: ""
              })
              .then(()=>{
                setSubmitting(false);
                handleClose();
              })
            }}
            >
            {formik =>
            <Form className={classes.root}>
              <Box className={classes.root}>
  
                <Field component={TextField} style={{width:'100%', marginTop:10, marginBottom:10}} label="Titolo" name="objTitleNew" type="text"/>
                <Field component={TextField} style={{width:'100%', marginTop:10, marginBottom:10}} multiline rows={4} label="Descrizione" name="objDescriptionNew" type="text"/>

                <FormControl style={{marginTop:15}} className={classes.formControl}>
                  <InputLabel id="points-simple-select-outlined-label">{t('Objectives.points')}</InputLabel>
                  <Field
                      component={Select}
                      name="objPointsNew"
                      inputProps={{
                      id: 'points-simple-select-outlined-label',
                      labelId: "points-simple-select-outlined-label"
                      }}
                  >
                      {points.map(point=><MenuItem key={point} value={point}>{point}</MenuItem>)}
                  </Field>
                </FormControl>
                <div className={classes.left}><Typography variant="caption" className={classes.dark}><ErrorMessage name="objPointsNew"/></Typography></div>

                <FormControl className={classes.formControl}>
                  <InputLabel id="duration-simple-select-outlined-label">{t('Objectives.duration')}</InputLabel>
                  <Field
                      component={Select}
                      name="objDurationNew"
                      inputProps={{
                      id: 'duration-simple-select-outlined-label',
                      labelId: "duration-simple-select-outlined-label"
                      }}
                  >
                      {days.map(day=><MenuItem key={day.value} value={day.value}>{day.label}</MenuItem>)}
                  </Field>
                </FormControl>
                <div className={classes.left}><Typography variant="caption" className={classes.dark}><ErrorMessage name="objDurationNew"/></Typography></div>

                <DialogActions>
                  <Button type='button' onClick={handleClose} color="secondary">
                    {t('General.cancel')}
                  </Button>
                  <Button type="submit" color="primary">
                  {formik.isSubmitting ? <CircularProgress/> : t('General.edit')}
                  </Button>
                </DialogActions>
              </Box>
            </Form>
            }
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}