import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import LetterAvatars from './LetterAvatars';

import Paper from '@material-ui/core/Paper';
import { Button, Card, CardActions, CardContent, /*Chip, */  CircularProgress, IconButton, Switch, Tooltip, Typography } from '@material-ui/core';
import QuestionSlider from './QuestionSlider';
import { PieChart } from 'react-minimal-pie-chart';
import DeleteIcon from '@material-ui/icons/Delete';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import TimerIcon from '@material-ui/icons/Timer';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import StarIcon from '@material-ui/icons/Star';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import { ArrowForward } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export default function HowToCard({section}) {

  const {t} = useTranslation()

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'start',
      marginTop: theme.spacing(3),
      alignItems: 'center',
      maxWidth: 500,
    },
    s_root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'center',
      margin: 'auto',
      alignItems: 'center',
      padding: theme.spacing(4),
      maxWidth: 500,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'start',
      height: 50,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      marginBottom: theme.spacing(1),
    },
    checkbox: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      height: 50,
      marginBottom: theme.spacing(1),
    },
    question: {
      height: 'auto',
      minWidth: 200,
      overflow: 'hidden',
      display: 'block',
      textAlign: 'center',
      width: '100%',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    stepperButtons: {
        width: '100%',
        backgroundColor: theme.palette.primary.contrastText,
    },
    i_root: {
      maxWidth: 300,
      cursor: 'default',
      '&:hover': {
        backgroundColor: '#f7f7f7',
      },
    },
    email: {
      marginBottom: theme.spacing(1),
    },
    defaultcursor: {
      cursor: 'default',
    },
    cardActions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    cardActionArea: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      cursor: 'default',
      padding: 0,
    },
    mycircle: {
      position: "relative",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    progresstext: {
      position: "absolute",
      top:"50%",
      left:"50%",
      transform: "translate(-50%,-50%)",
    },
    progressElements: {
      display: 'flex',
    },
    r_root: {
      maxWidth: 500,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: "space-between",
      cursor: "pointer",
      '&:hover': {
        backgroundColor: '#f7f7f7',
      },
      paddingTop:0,
      paddingBottom:0,
    },
    cardcontent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingTop:0,
      paddingBottom:0,
    },
    r_cardActions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
      paddingTop:0,
      paddingBottom:0,
    },
    labels: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop:0,
      paddingBottom:0,
    },
    pie: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop:0,
      paddingBottom:0,
    },
    withMargins: {
      marginRight: theme.spacing(1),
    },
    o_root: {
      maxWidth: 500,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      cursor: 'default',
    },
    spaceBetween: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      cursor: 'default',
      minHeight:50,
    },
    spaceBetweenColumns: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      cursor: 'default',
      padding: '0!isPartner',
    },
    center: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    thumbs: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
      marginTop: theme.spacing(3)
    },
    status: {
      marginTop: theme.spacing(0),
      marginRight: theme.spacing(1),
    },
    title: {
      width: '100%',
      textAlign: 'center',
    },
    fab: {
      width: 25,
      height: 25,
      minHeight:25,
    },
    self: {
      color: theme.palette.secondary.main,
    },
    partner: {
      color: theme.palette.primary.main,
    },
    borderedIcon: {
      borderColor: "#C0C0C0",
      borderStyle: 'solid',
      borderWidth: '1px',
      '&:hover': {
        borderColor: theme.palette.secondary.main,
      }
    },
    centerTop: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    right: {
      display: 'flex',
      flexDirection: 'row',
      textAlign: 'right',
      alignItems: 'end',
      justifyContent: 'flex-end'
    },
  }));

  const classes = useStyles();
  const theme = useTheme();

  const [value, setValue] = React.useState(80);
  const [isPartner, setIsPartner] = React.useState(true);

  const handleChange = (event) => setIsPartner(event.target.checked);

  const [obj_achieved, setObj_achieved] = React.useState('eval');

  const data = [
    {title: t('General.questions')+" "+t('Results.critical'), value: 10, color: theme.palette.secondary.main},
    {title: t('General.questions')+" "+t('Results.disaligned'), value: 20, color: theme.palette.primary.dark},
    {title: t('General.questions')+" "+t('Results.aligned'), value: 90, color: theme.palette.primary.light},
  ]

  function switchCallToAction() {
    switch(section) {
      case 'survey':
        return t('HowTo.survey.tagline')
      case 'invites':
        return t('HowTo.invites.tagline')
      case 'results':
        return t('HowTo.results.tagline')
      case 'objectives':
        return t('HowTo.objectives.tagline')
      default:
        return null
    }
  }
                    
  function switchSection() {
    switch(section) {
      case 'survey':
        return (    
          <div className={classes.i_root}>
          <Typography style={{marginBottom:15}} variant="body2" color="primary">
          {t('HowTo.survey.info')}
          </Typography>    
          <Paper elevation={3} className={classes.s_root}>
            <Paper square elevation={3} className={classes.header}>
              <Typography variant="h6">{t('HowTo.survey.title')}</Typography>
            </Paper>
            <Typography variant="body1" className={classes.question}>
            {t('HowTo.survey.description')}
            </Typography>
            <QuestionSlider value={value} setValue={setValue}/>
          </Paper>
          </div>
        );
      case 'invites':
        return (
          <div className={classes.i_root}>
          <Typography style={{marginBottom:15}} variant="body2" color="primary">
          {t('HowTo.invites.info')}
          </Typography>    
          <Card style={{width:450}} className={classes.i_root}>
            <div className={classes.cardActionArea}>
              <div className={classes.cardActions}>
                <LetterAvatars initials="AR" type={"invitee"}/>
              </div>
              <CardContent className={classes.defaultcursor}>
                <Typography gutterBottom variant="h6" component="h2">
                {t('HowTo.invites.name')}
                </Typography>
                <Typography className={classes.email} style={{marginBottom:0}} variant="body2" color="textSecondary" component="p">
                {t('HowTo.invites.waiting')}
                </Typography>
                <div style={{marginBottom: 15, display:'flex', alignItems:'center'}}>
                <SubdirectoryArrowRightIcon fontSize='small' color="primary"/>
                <Typography variant="caption" color="primary">
                {t('HowTo.invites.statusDialog')}
                </Typography>
                </div>
                <Typography style={{marginTop:15}} variant="caption" color="textSecondary">
                {t('Invites.partner')}
                </Typography>
                <Switch 
                size="small" 
                color="primary" 
                checked={isPartner} 
                onChange={handleChange} 
                name="checked"/><br/>
                <div style={{display:'flex', alignItems:'center'}}>
                <SubdirectoryArrowRightIcon fontSize='small' color="primary"/>
                <Typography variant="caption" color="primary">
                {t('HowTo.invites.partnerDialog')}
                </Typography>
                </div>
              </CardContent>
            </div>
            <CardActions className={classes.cardActions}>
              <Button 
                size="small" 
                color="primary" 
              >REVOCA</Button>
              <Typography color='secondary' variant='caption'>{"70% "+t('General.complete')}</Typography>
            </CardActions>
          </Card>
          </div>
        );
      case 'results':
        return (
          <div className={classes.i_root}>
          <Typography style={{marginBottom:15}} variant="body2" color="primary">
          {t('HowTo.results.info')}    
          </Typography> 
          <Card className={classes.r_root}>
          <div>
            <CardContent className={classes.centerTop}>
              <Typography gutterBottom variant="h5" component="h2">
                {t('HowTo.invites.name')}
               </Typography>
              <div className={classes.mycircle}>
                <CircularProgress variant="determinate" size={40} value={40}/>  
                <Typography variant="body2" color="primary" className={classes.progresstext}>{40}%</Typography>
              </div>  
            </CardContent>
            <div className={classes.right} >
              <ArrowDownwardIcon style={{marginRight:30, marginTop: -10}} fontSize='small' color="primary"/>
            </div>
            <div className={classes.right} >
              <Typography style={{paddingLeft:15, paddingRight:15, marginBottom: 15}} variant="caption" color="primary">
               {t('HowTo.results.progressDialog')}
              </Typography>
            </div>
            <CardContent className={classes.pie}>
              <PieChart
                data={data}
                startAngle={-170}
                lengthAngle={160}
                totalValue={120}
                paddingAngle = {15}
                rounded = {true}
                animate = {true}
                lineWidth = {5}
                viewBoxSize={[100, 50]}
                label={({ dataEntry }) => dataEntry.value}
                labelStyle={(dataEntry) => ({
                  fill: dataEntry.color,
                  fontSize: '5px',
                  fontFamily: 'sans-serif',
                })}
                labelPosition={70}
              />
            </CardContent>
            <CardContent className={classes.cardcontent}>
              <Typography className={classes.center} variant="body1">
              {t('HowTo.results.title')}
              </Typography>
              <Typography className={classes.labels} variant="caption">
                <span style={{color:theme.palette.secondary.main}}>{t('Results.critical')}</span> 
                <span style={{color:theme.palette.primary.dark}}>{t('Results.disaligned')}</span> 
                <span style={{color:theme.palette.primary.light}}>{t('Results.aligned')}</span>
              </Typography>
            </CardContent>
          </div>
          <CardActions style={{marginBottom:10}} className={classes.r_cardActions}>
            {/* <div style={{width:130}}/> */}
            <Button color="primary" >{t('Results.details')}</Button>
            {/* <ArrowForward fontSize='small' color="primary"/>
            <Chip icon={<AccountCircleIcon />} label="Account Pro"  color="secondary"/> */}
          </CardActions>
        </Card>
        </div>
        );
      case 'objectives':
        return (    
          <div className={classes.i_root}>
          {/* <div style={{display:'flex', marginTop: 0, marginBottom:theme.spacing(1), justifyContent:'flex-end'}}>
            <Chip icon={<AccountCircleIcon />} label="Account Pro"  color="secondary"/>
          </div> */}
          <Typography style={{marginBottom: 15}} variant="body2" color="primary">
          {t('HowTo.objectives.info')}
          </Typography>
          <Card className={classes.o_root}>
          <div className={classes.main}>

            <div className={classes.spaceBetween}>
              <LetterAvatars initials={7} small="pt" type={"self"}/>
    
              <Typography className={classes.status} variant="caption">
                <span className={classes.self}>
                <Tooltip title="Elimina">
                  <IconButton className={classes.borderedIcon} aria-label="delete" size="small">
                    <DeleteIcon className={classes.self}/>
                  </IconButton>
                </Tooltip>
                </span>
              </Typography>
    
            </div>
    
            <CardContent className={classes.spaceBetweenColumns}>
    
              <Typography variant="body2" className={classes.center}>
              <span className={classes.self}>
                <TimerIcon className={classes.self} style={{ fontSize: 20 }}/>
                14{t('Objectives.durationDay')}
              </span>
              </Typography>
              <ArrowDownwardIcon fontSize='small' color="primary"/>
              <Typography className={classes.center} style={{paddingLeft:15, paddingRight:15}} variant="caption" color="primary">
              {t('HowTo.objectives.durationDialog')}
              </Typography><br/>
    
              <Typography className={classes.title} variant="body1">
              <span className={classes.self}>{t('HowTo.objectives.title')}</span>
              </Typography>
    
              <Typography style={{paddingLeft:15, paddingRight:15}} variant="caption" className={classes.self}>
              {t('HowTo.objectives.description')}
              </Typography>
              <ArrowDownwardIcon fontSize='small' color="primary"/>
              <Typography className={classes.center} style={{paddingLeft:15, paddingRight:15}} variant="caption" color="primary">
              {t('HowTo.objectives.descriptionDialog')}
              </Typography>
    
            </CardContent>
    
          </div>
    
          <CardActions className={classes.spaceBetween}>
    
            <div className={classes.self}>
            <Button 
              startIcon={<ThumbDownIcon />} 
              aria-label="delete" 
              size="small" 
              variant={obj_achieved==="false" ? "contained" : "outlined"} 
              color="secondary" 
              onClick={()=>setObj_achieved('false')}
            >
              NO
            </Button>
            </div>

            <div className={classes.self} style={{marginLeft:15, marginRight:5}}>
              <Typography variant="caption" color="primary">
              {t('HowTo.objectives.evaluationDialog')}
              </Typography>
            </div>

            <Button 
              startIcon={<ThumbUpIcon />} 
              aria-label="delete" 
              size="small" 
              variant={obj_achieved==="true" ? "contained" : "outlined"} 
              color="secondary" 
              onClick={()=>setObj_achieved('true')}
            >
              SI
            </Button>
    
          </CardActions>
        </Card>
        </div>
        );
      default: 
        return null
    }
  }
  return (
    <div className={classes.root}>
      {switchSection()}
      <div style={{display:'flex', flexDirection:'row', width: '100%', justifyContent:'space-evenly', alignItems:'center', marginTop:theme.spacing(1)}}>
      <StarIcon color='primary'/>
      <Typography variant="body1" color='primary'><b>{switchCallToAction()}</b></Typography>
      <StarIcon color='primary'/>
      </div>
    </div>
  );
}