import React, {useContext} from 'react';

import userContext from '../Contexts/userContext';

import AddPartner from '../Components/Modals/AddPartner';
import PartnerCard from '../Components/PartnerCard';

import { makeStyles } from '@material-ui/core/styles';
import {  Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    mygrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(225px, 1fr))',
        gap: '20px',
        width: '100%',
        margin: theme.spacing(3),
        padding: theme.spacing(3),
    },
    mybutton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
    },
    disclaimer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop:theme.spacing(6),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
}));

function InvitesRoute() {

    const {t} = useTranslation()

    const userState = useContext(userContext);
    const { activePartners} = userState;
    
    const matchedPartners = activePartners.filter(partner => partner.matchingType === "matched")
    const inviteePartners = activePartners.filter(partner => partner.matchingType === "invitee")
    const inviterPartners = activePartners.filter(partner => partner.matchingType === "inviter")

    const classes = useStyles();

    return (
        <div className={classes.root}>

            {/* Notifications components not implemented due to compatibility issues with iOS browsers */ }
            {/*(Notification.permission !== "granted") ? <Typography color="primary" variant="caption"><b>{t('Notifications.invite')}</b></Typography> : null*/}

            <div className={classes.mybutton}>
                <AddPartner/>
            </div>
            <div className={classes.disclaimer}>
                <Typography variant="body1" color="primary">{t('Invites.disclaimer')}</Typography>
            </div>
            <div className={classes.mygrid}>
                {matchedPartners.map(matched => (
                    <PartnerCard key={matched.email} partnerEmail={matched.email} partnerType={matched.partnerType} matchingType={"matched"} partnerProgress={matched.surveyProgress} partnerDisplayName={matched.displayName} />
                ))}
                {inviterPartners.map(inviter => (
                    <PartnerCard key={inviter.email} partnerEmail={inviter.email} partnerType={inviter.partnerType} matchingType={"inviter"} partnerProgress={inviter.surveyProgress} partnerDisplayName={inviter.displayName} />
                ))}
                {inviteePartners.map(invitee => (
                    <PartnerCard key={invitee.email} partnerEmail={invitee.email} partnerType={invitee.partnerType} matchingType={"invitee"} partnerProgress={invitee.surveyProgress} partnerDisplayName={invitee.email}/>
                ))}
            </div>
        </div>
    );
}

export default InvitesRoute;