import React, { useContext } from 'react';

import clsx from 'clsx';

import authContext from '../../Contexts/authContext';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import InfoIcon from '@material-ui/icons/Info';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MailIcon from '@material-ui/icons/Mail';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SchoolIcon from '@material-ui/icons/School';
import EuroIcon from '@material-ui/icons/Euro';
import PersonIcon from '@material-ui/icons/Person';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  menu: {
      padding: theme.spacing(0),
      marginRight: theme.spacing(1),
      marginTop: 0,
      marginBottom: 0,
      height: 24,
      cursor: 'pointer',
      float: 'left',
      color: theme.palette.secondary.contrastText,
      '&:hover': {
        color:theme.palette.primary.main,
      },
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  icon: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
  },
}));

export default function TemporaryDrawer() {

  const {t} = useTranslation()

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const authState = useContext(authContext);
  const {isSignedIn} = authState;    
  let history = useHistory();

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  function switchRoute(text) {
    switch (text) {
      case 'About':
        return "/about";
      case 'How To':
        return "/howto";
      case 'Pricing':
        return "/pricing";
      case "Survey":
        return "/survey";
      case "Invites":
        return "/invites";
      case "Results":
        return "/AggregatedResults"
      case "Objectives":
        return "/objectives"
      case "Profile":
        return "/profile"
      default:
        return "/"
    }
  }

  function switchText(text) {
    switch (text) {
      case 'About':
        return t('Drawer.about');
      case 'How To':
        return t('Drawer.howto');
      case 'Pricing':
        return t('Drawer.pricing');
      case "Survey":
        return t('Drawer.survey');
      case "Invites":
        return t('Drawer.invites');
      case "Results":
        return t('Drawer.results')
      case "Objectives":
        return t('Drawer.objectives')
      case "Profile":
        return t('Drawer.profile')
      default:
        return "/"
    }
  }


  function switchIcon(text) {
    switch (text) {
      case 'About':
        return <InfoIcon className={classes.icon}/>;
      case 'How To':
        return <MenuBookIcon className={classes.icon}/>;
      case 'Pricing':
        return <EuroIcon className={classes.icon}/>;
      case "Survey":
        return <QuestionAnswerIcon className={classes.icon}/>;
      case "Invites":
        return <MailIcon className={classes.icon}/>;
      case "Results":
        return <AssessmentIcon className={classes.icon}/>
      case "Objectives":
        return <SchoolIcon className={classes.icon}/>
      case "Profile":
        return <PersonIcon className={classes.icon}/>
        default:
        return null
    }
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['About', 'How To' /*, 'Pricing' */].map((text, index) => (
          <ListItem button onClick={()=>history.push(switchRoute(text))} key={text}>
            <ListItemIcon>{switchIcon(text)}</ListItemIcon>
            <ListItemText primary={switchText(text)} />
          </ListItem>
        ))}
      </List>
      {(isSignedIn) ? <Divider /> : null}
      <List>
        {(isSignedIn) ? ['Profile'].map((text, index) => (
          <ListItem button onClick={()=>history.push(switchRoute(text))} key={text}>
            <ListItemIcon>{switchIcon(text)}</ListItemIcon>
            <ListItemText primary={switchText(text)} />
          </ListItem>
        )) : null}
      </List>
      <Divider />
      <List>
        {(isSignedIn) ? ['Survey', 'Invites', 'Results', 'Objectives'].map((text, index) => (
          <ListItem button onClick={()=>history.push(switchRoute(text))} key={text}>
            <ListItemIcon>{switchIcon(text)}</ListItemIcon>
            <ListItemText primary={switchText(text)} />
          </ListItem>
        )) : null}
      </List>
    </div>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <ul onClick={toggleDrawer(anchor, true)} className={classes.menu}><MenuIcon/></ul>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}