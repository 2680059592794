import * as React from 'react';

import userContext from '../Contexts/userContext';
import objectivesContext from '../Contexts/objectivesContext';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Avatar, Button, Typography } from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';

import firebase from 'firebase/app'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    padding: theme.spacing(2),
    position:'relative'
},
  myGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
      gap: '20px',
      width: '100%',
      margin: theme.spacing(0),
      padding: theme.spacing(0),
  },
  topBand: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginBottom:theme.spacing(1),
    width:'100%',
    alignItems: 'center',
  },
  corners: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width:'50px',
    alignItems: 'center',
  },
  avatar: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: theme.spacing(3)
  },
  button: {
    position: 'absolute',
    bottom: theme.spacing(2)
  },
  description: {
    marginBottom: theme.spacing(5)
  },
}));

export default function SuggestedObjectivesGrid({objectives}) {

  const {t} = useTranslation()

  const classes = useStyles();

  const [added, setAdded] = React.useState(Array(objectives.length).fill(false))

  const userState = React.useContext(userContext);
  const { activeUser } = userState;
  const { uid } = activeUser

  const objectivesState = React.useContext(objectivesContext);
  const {objectivesPartner} = objectivesState;

  async function AddSuggestedObjective({index, objTitle, objDescription, objDuration, objPoints}) {
    let tempAdded = added;
    tempAdded[index] = true;
    setAdded(tempAdded);
    const newObjectiveRef = firebase.firestore().collection('users').doc(uid).collection('partners').doc(objectivesPartner.email).collection('objectives').doc()
    newObjectiveRef.set({
      objId: newObjectiveRef.id,
      objTitle: objTitle,
      objDescription: objDescription,
      objPoints: objPoints,
      objDuration: objDuration,
      objCreated: firebase.firestore.FieldValue.serverTimestamp(),
      objAchieved: "eval",
      objStatus: "pending",
      objComments: ""
    })
    .catch()
  }

  return (
    <div className={classes.myGrid}>
          {objectives.map((row, index) => {
            return <Paper className={classes.root} elevation={3} key={index}>
              <div className={classes.topBand}>
                <div className={classes.corners}><Avatar className={classes.avatar}><Typography variant="h6">{row.objPoints}</Typography><Typography variant="caption">pt</Typography></Avatar></div>

                <div className={classes.corners}><Typography variant="caption"><TimerIcon/>{row.objDuration}gg</Typography></div>
              </div>
              <Typography className={classes.title} variant="body1" color="secondary"><b>{row.objTitle}</b></Typography>
              <div className={classes.description}>
                <Typography variant="caption" color="secondary">{row.objDescription}</Typography>
              </div>
              <div className={classes.button}>
                <Button 
                disabled={(objectivesPartner.email ==="" || !objectivesPartner.email || added[index]) ? true : false} 
                onClick={()=>AddSuggestedObjective({index:index, objTitle:row.objTitle, objDescription:row.objDescription, objDuration:row.objDuration, objPoints:row.objPoints})} 
                variant="outlined" 
                color="primary" 
                size="small">{t('General.add')}</Button>
              </div>
              </Paper>
          })}
    </div>
  );
}
