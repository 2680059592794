import React, {useContext, useState} from 'react';
import { useHistory } from 'react-router-dom';

import userContext from '../Contexts/userContext';
import objectivesContext from '../Contexts/objectivesContext';

import AddObjective from '../Components/Modals/AddObjective';
import ObjectiveCard from '../Components/ObjectiveCard';
import SelectPartner from '../Components/SelectPartner';
import LetterAvatars from '../Components/LetterAvatars';

import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        alignItems: 'center',
    },
    points: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding:theme.spacing(3)
    },
    mygrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(225px, 1fr))',
        gap: '20px',
        width: '100%',
        margin: theme.spacing(3),
        padding: theme.spacing(3),
    },
    mybutton: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
    },
    mycircle: {
        position: "relative",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(3),
        marginTop:theme.spacing(6)
    },
    target: {
        textAlign:'center'
    },
    disclaimer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop:theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    progresstext: {
        position: "absolute",
        top:"50%",
        left:"50%",
        transform: "translate(-50%,-50%)",
    },
}));

export default function ObjectivesRoute() {

    const {t} = useTranslation()

    let history = useHistory();

    const [myswitch, setMyswitch] = useState(false);

    const objectivesState = useContext(objectivesContext);
    const {objectivesPartner, setObjectivesPartner} = objectivesState;

    const userState = useContext(userContext);
    const {activeUser, activePartners, activeObjectives} = userState;
    const matchedPartners = activePartners.filter(partner => partner.matchingType === "matched")
    const objectives = activeObjectives.user.concat(activeObjectives.partner)

    const classes = useStyles();


    const handleSwitch = (event) => {
        setMyswitch(event.target.checked);
    };

    function awardsPoints(objective) {
        if (
            (objective.objStatus === 'accepted' || objective.objStatus === "expired" || objective.objStatus === "archived") &&
            (objective.objAchieved === "true" )
        ) return objective.objPoints
        else return 0;
    }

    const selfPoints = objectives.filter(objective =>
        objective.proposer === "partner"
    ).reduce((accumulator, objective) => {
        return (accumulator + awardsPoints(objective))
    }
    ,0)
    
    const partnerPoints = objectives.filter(objective =>
        objective.proposer === "self"
    ).reduce((accumulator, objective) => (
        accumulator + awardsPoints(objective)
    )
    ,0)

    const sortingLogic = {
        'partner': 20000,
        'self': 10000,
        
        'accepted': 5000,
        'pending': 4000,
        'expired': 3000,
        'rejected': 2000,
        'archived': 1000,
        
        'eval': 300,
        'true': 200,
        'false': 100,
    }

    const surveysComplete = (objectivesPartner.surveyProgress === 100 && activeUser.surveyProgress === 100) ? true : false
    const match = (objectivesPartner.partnerType && objectivesPartner.results && objectivesPartner.results[objectivesPartner.partnerType].aggregatedResults) ? parseInt(100 * objectivesPartner.results[objectivesPartner.partnerType].aggregatedResults.match) : 0
    const target = (100 - match) * 10 / 2

    return (
        <div className={classes.root}>

            {/* Notifications componenets not implemented due to compatibility issues with iOS browsers */ }
            {/*(Notification.permission !== "granted") ? 
            <div className={classes.disclaimer}><Typography color="primary" variant="caption"><b>{t('Notifications.invite')}</b></Typography></div> : 
            null */}

            <div className={classes.points}>
                {objectivesPartner.email ? <div className={classes.root}>{activeUser.displayName}<LetterAvatars large={selfPoints} initials="pt" type="selfTotalScore"/></div> : null}
                {(objectivesPartner.email && surveysComplete) ? <div className={classes.root}><Typography className={classes.target} color="primary" variant="body1"><Trans i18nKey="Objectives.target" match={match} target={target}>Avete una affinità del {{match}}%. Per aumentarla datevi degli obiettivi sulle aree tematiche più critiche per almeno {{target}} punti a testa.</Trans></Typography></div> : null}
                {objectivesPartner.email ? <div className={classes.root}>{objectivesPartner.displayName}<LetterAvatars large={partnerPoints} initials="pt" type="partnerTotalScore"/></div> : null}
            </div>
                
            <div>
                <div className={classes.mybutton}>
                    <SelectPartner 
                    options={matchedPartners} 
                    choice={objectivesPartner} 
                    setChoice={setObjectivesPartner}/>
                </div>

                <div className={classes.mybutton}>
                    <AddObjective/>
                </div>

                <div className={classes.mybutton}>
                    <Button disabled={(objectivesPartner.email ==="" || !objectivesPartner.email)? true : false} variant="outlined" color="primary" style={{width:175, paddingLeft:0, paddingRight:0}} size='large' onClick={()=>history.push("/suggestedObjectives")}>{t('Objectives.suggested')}</Button>
                </div>

                <div className={classes.mybutton}>
                <Switch
                    checked={myswitch}
                    onChange={handleSwitch}
                    name="checked"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Typography color="primary" variant="caption">{t('General.showAll')}</Typography>
                </div>

            </div>


            <div className={classes.mygrid}>
                {objectives.filter(objective => {
                    if (myswitch) return true;
                    return !(objective.objStatus === "archived") &&
                    !(objective.objStatus === "expired" && objective.proposer === "self") &&
                    !(objective.objStatus === "rejected" && objective.proposer === "partner")
                })
                .sort((a, b)=>{
                    function score(x) { return sortingLogic[x.proposer] + sortingLogic[x.objStatus] + sortingLogic[x.objAchieved] + 1/x.obj_timeRemaining }
                    return score(b) - score(a)
                })
                .map((objective, index) => (
                    <ObjectiveCard key={"objective-"+index}
                    obj={objective}
                    />
                ))}
            </div>
        </div>
    );
}