import React, { useContext } from 'react';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { TextField } from 'formik-material-ui';

import Button from '@material-ui/core/Button';
import { Box, makeStyles, Typography } from '@material-ui/core';

import firebase from "firebase/app";
import "firebase/auth";
import authContext from '../Contexts/authContext';

const useStyles = makeStyles( (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin:'auto',
        width: '350px',
    },
    terms: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
    },
    formControl: {
        width: '100%',
        marginBottom: theme.spacing(2)
      },
    myCheckBox: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
    },
    left: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        width: '100%'
    },
    dark: {
        color: theme.palette.error.main
    }
  }));

export default function DevelopmentSignIn() {

    const authState = useContext(authContext);
    const {setJustRegistered} = authState;  

    const classes = useStyles(); 

    return (

        <div>
 
        <Formik
        initialValues={{ email: '', password: ''}}

        validationSchema={Yup.object({
            email: Yup.string()
            .required('Obbligatorio'),
            password: Yup.string()
            .required('Obbligatorio'),
        })}

        onSubmit = { (values, {setSubmitting}) => {
            firebase.auth().signInWithEmailAndPassword(values.email, values.password)
            .then((user) => {
            })
            .catch((error) => {
            });
        }}            >
            <Form className={classes.root}>
                <Box className={classes.root}>

                    <Typography color="primary" variant="body1" style={{marginTop:5, marginBottom:15}}>Login</Typography>

                    <Field component={TextField} style={{width:'100%', marginTop:10, marginBottom:10}} label="Email" name="email" type="email"/>

                    <Field component={TextField} style={{width:'100%', marginTop:10, marginBottom:10}} label="Password" name="password" type="password"/>

                    <Button variant="contained" size="large" color="primary" type="submit" style={{marginTop: "1rem", marginBottom: "1rem", width: '100%'}}>
                        Login
                    </Button>
                </Box>
            </Form>
        </Formik>

        <Formik
        initialValues={{ email: '', displayName: '', password: ''}}

        validationSchema={Yup.object({
            email: Yup.string()
            .required('Obbligatorio'),
            displayName: Yup.string()
            .required('Obbligatorio'),
            password: Yup.string()
            .required('Obbligatorio'),
        })}

        onSubmit = { async (values, {setSubmitting}) => {
            setJustRegistered(true)
            await firebase.auth().createUserWithEmailAndPassword(values.email, values.password)
            .then(async (userCredentials) => {
                await userCredentials.user.updateProfile({
                    displayName: values.displayName
                })
                // Update displayName in Firestore (not available in back-end at the time of creation)
                await firebase.firestore().collection("users").doc(userCredentials.user.uid).set({
                    displayName: values.displayName,
                    email: userCredentials.user.email,
                    uid: userCredentials.user.uid
                    }, { merge: true })
                .catch()
                await userCredentials.user.sendEmailVerification()
            })
            .catch((error) => {
            });
            setJustRegistered(false)
        }}            >
            <Form className={classes.root}>
                <Box className={classes.root}>

                    <Typography color="primary" variant="body1" style={{marginTop:5, marginBottom:15}}>Register</Typography>

                    <Field component={TextField} style={{width:'100%', marginTop:10, marginBottom:10}} label="Email" name="email" type="email"/>
                    <Field component={TextField} style={{width:'100%', marginTop:10, marginBottom:10}} label="Display Name" name="displayName" type="text"/>
                    <Field component={TextField} style={{width:'100%', marginTop:10, marginBottom:10}} label="Password" name="password" type="password"/>

                    <Button variant="contained" size="large" color="primary" type="submit" style={{marginTop: "1rem", marginBottom: "1rem", width: '100%'}}>
                        Register
                    </Button>
                </Box>
            </Form>
        </Formik>

        </div>

  );
};