import React, { useContext } from 'react';

import LetterAvatars from './LetterAvatars';

import userContext from '../Contexts/userContext'

import EditObjective from './Modals/EditObjective';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Button, CardActions } from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';
import DeleteIcon from '@material-ui/icons/Delete';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import firebase from 'firebase/app'
import objectivesContext from '../Contexts/objectivesContext';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme)=>({
  root: {
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'default',
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    cursor: 'default',
    minHeight:50,
  },
  spaceBetweenColumns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    cursor: 'default',
    padding: '0!important',
  },
  center: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  thumbs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(3)
  },
  progressElements: {
    display: 'flex',
  },
  status: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  title: {
    width: '100%',
    textAlign: 'center',
  },
  fab: {
    width: 25,
    height: 25,
    minHeight:25,
  },
  self: {
    color: theme.palette.secondary.main,
  },
  partner: {
    color: theme.palette.primary.main,
  },
  borderedIcon: {
    borderColor: "#C0C0C0",
    borderStyle: 'solid',
    borderWidth: '1px',
    '&:hover': {
      borderColor: theme.palette.secondary.main,
    }
  },
}));

export default function ObjectiveCard({obj}) {

  const {t} = useTranslation()

  const {
    proposer, 
    objDescription, 
    objAchieved,
    objId, 
    objPoints, 
    objStatus, 
    objDuration,
    objTitle,
    objCreated,
    objComments
  } = obj
    
  const classes = useStyles();

  const userState = useContext(userContext);
  const {activeUser} = userState;
  const {uid, email} = activeUser

  const objectivesState = useContext(objectivesContext);
  const {objectivesPartner} = objectivesState;

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const oneHour = 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const objTimeRemaining = objCreated.seconds*1000 + objDuration*oneDay - Date.now()
  if (objTimeRemaining < 0 && objStatus !== "expired" && objStatus !== "archived") SetObjectiveExpired()
  const obj_daysRemaining = Math.max(Math.round( objTimeRemaining / oneDay ),0);
  const obj_hoursRemaining = Math.max(Math.round(( objTimeRemaining - oneDay*obj_daysRemaining ) / oneHour),0);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = ({newComments}) => {
    RejectObjective({newComments})
    setAnchorEl(null);
  };

  async function DeleteObjective() {
    firebase.firestore().collection('users').doc(uid).collection('partners').doc(objectivesPartner.email).collection('objectives').doc(objId).delete()
    .catch()
  }

  async function AcceptObjective() {
    firebase.firestore().collection('users').doc(objectivesPartner.uid).collection('partners').doc(email).collection('objectives').doc(objId).update({
      objStatus: "accepted",
      objCreated: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .catch()
  }

  async function RejectObjective({newComments}) {
    firebase.firestore().collection('users').doc(objectivesPartner.uid).collection('partners').doc(email).collection('objectives').doc(objId).update({
      objStatus: "rejected",
      objComments: newComments,
    })
    .catch()
  }

  async function ArchiveObjective() {
    firebase.firestore().collection('users').doc(objectivesPartner.uid).collection('partners').doc(email).collection('objectives').doc(objId).update({
      objStatus: "archived"
    })
    .catch()
  }

  async function SetObjectiveAchieved() {
    firebase.firestore().collection('users').doc(uid).collection('partners').doc(objectivesPartner.email).collection('objectives').doc(objId).update({
      objAchieved: "true",
    })
    .catch()
  }

  async function SetObjectiveFailed() {
    firebase.firestore().collection('users').doc(uid).collection('partners').doc(objectivesPartner.email).collection('objectives').doc(objId).update({
      objAchieved: "false",
    })
    .catch()
  }

  async function SetObjectiveExpired() {
    if (proposer === "self") {
      firebase.firestore().collection('users').doc(uid).collection('partners').doc(objectivesPartner.email).collection('objectives').doc(objId).update({
        objStatus: "expired",
      })
      .catch()
    }

    if (proposer === "partner") {
      firebase.firestore().collection('users').doc(objectivesPartner.uid).collection('partners').doc(email).collection('objectives').doc(objId).update({
        objStatus: "expired",
      })
      .catch()
    }
  }

  function switchBottomLeft() {
    switch(proposer) {
      case 'self':
          return switchBottomLeftSelf();
      case 'partner':
          return switchBottomLeftPartner();
      default:
        return null;
    }
  }

  function switchBottomRight() {
    switch(proposer) {
      case 'self':
          return switchBottomRightSelf();
      case 'partner':
          return switchBottomRightPartner();
      default:
        return null;
    }
  }

  function switchBottomMiddle() {
    switch(proposer) {
      case 'self':
          return switchBottomMiddleSelf();
      case 'partner':
          return switchBottomMiddlePartner();
      default:
        return null;
    }
  }

  function switchBottomLeftSelf() {
    switch(objStatus) {
      case 'pending':
          return (null);
      case 'rejected':
          return (null);
      case 'accepted':
        return (<Button startIcon={<ThumbDownIcon />} aria-label="delete" size="small" variant={objAchieved==="false" ? "contained" : "outlined"} color="secondary" onClick={SetObjectiveFailed}>{t('General.no')}</Button>);
      case 'expired':
        return (objAchieved === "false" ? <ThumbDownIcon /> : (objAchieved === "eval" ? <Button startIcon={<ThumbDownIcon />} aria-label="delete" size="small" variant={objAchieved==="false" ? "contained" : "outlined"} color="secondary" onClick={SetObjectiveFailed}>{t('General.no')}</Button> : null));
      case 'archived':
        return (objAchieved === "false" ? <ThumbDownIcon /> : null);
      default:
        return null;
    }
  }

  function switchBottomRightSelf() {
    switch(objStatus) {
      case 'pending':
          return (<EditObjective objId={objId} objTitle={objTitle} objDescription={objDescription} objDuration={objDuration} objPoints={objPoints} />);
      case 'rejected':
        return (<EditObjective objId={objId} objTitle={objTitle} objDescription={objDescription} objDuration={objDuration} objPoints={objPoints} />);
      case 'accepted':
        return (<Button startIcon={<ThumbUpIcon />} aria-label="delete" size="small" variant={objAchieved==="true" ? "contained" : "outlined"} color="secondary" onClick={SetObjectiveAchieved}>SI</Button>);
      case 'expired':
        return (objAchieved === "true" ? <ThumbUpIcon /> : (objAchieved === "eval" ? <Button startIcon={<ThumbUpIcon />} aria-label="delete" size="small" variant={objAchieved==="true" ? "contained" : "outlined"} color="secondary" onClick={SetObjectiveAchieved}>{t('General.yes')}</Button> : null));
      case 'archived':
        return (objAchieved === "true" ? <ThumbUpIcon /> : null);
      default:
        return null;
    }
  }

  function switchBottomRightPartner() {
    switch(objStatus) {
      case 'pending':
        return (<Button endIcon={<CheckCircleIcon />} aria-label="edit" size="small" onClick={AcceptObjective} color="primary" variant="outlined">{t('Objectives.accept')}</Button>);
      case 'rejected':
        return (null);
      case 'accepted':
        return (objAchieved === "true" ? <ThumbUpIcon /> : null);
      case 'expired':
        return (objAchieved === "true" ? <ThumbUpIcon /> : null);
      case 'archived':
        return (objAchieved === "true" ? <ThumbUpIcon /> : null);
      default:
        return null;
    }
  }

  function switchBottomLeftPartner() {
    switch(objStatus) {
      case 'pending':
        return (<Button startIcon={<CancelIcon />} aria-label="edit" size="small" onClick={handleClick} color="primary" variant="outlined">{t('Objectives.reject')}</Button>);;
      case 'rejected':
        return (null);
      case 'accepted':
        return (objAchieved === "false" ? <ThumbDownIcon /> : null);
      case 'expired':
        return (objAchieved === "false" ? <ThumbDownIcon /> : null);
      case 'archived':
        return (objAchieved === "false" ? <ThumbDownIcon /> : null);
      default:
        return null;
    }
  }

  function switchBottomMiddlePartner() {
    switch(objStatus) {
      case 'pending':
        return (null);
      case 'rejected':
        return <Typography className={classes[proposer]} variant="caption">{t('Objectives.rejected')}</Typography>;
      case 'accepted':
        return (objAchieved === "eval" ? <Typography className={classes.partner} variant="caption">{t('Objectives.evalWaiting')}</Typography> : <Typography className={classes.partner} variant="caption">{t('Objectives.evaluation')+" Partner"}</Typography>);
      case 'expired':
        return (objAchieved === "eval" ? <Typography className={classes.partner} variant="caption">{t('Objectives.evalMissing')}</Typography> : <Typography className={classes.partner} variant="caption">{t('Objectives.timeOver')}</Typography>);
      case 'archived':
        return <Typography className={classes[proposer]} variant="caption">{t('Objectives.archived')}</Typography>;  
      default:
        return null;
    }
  }

  function switchBottomMiddleSelf() {
    switch(objStatus) {
      case 'pending':
        return <Typography className={classes[proposer]} variant="caption">{t('Objectives.pending')}</Typography>;
      case 'rejected':
        return <Typography className={classes[proposer]} variant="caption">{t('Objectives.rejected')}</Typography>;
      case 'accepted':
        return switchBottomMiddleSelfAccepted();
      case 'expired':
        return switchBottomMiddleSelfExpired();
      case 'archived':
        return <Typography className={classes[proposer]} variant="caption">{t('Objectives.archived')}</Typography>;  
      default:
        return null;
    }
  }

  function switchBottomMiddleSelfAccepted() {
    switch(objAchieved) {
      case 'true':
        return <Typography className={classes.self} variant="caption">{t('Objectives.success')}</Typography>;
      case 'false':
        return <Typography className={classes.self} variant="caption">{t('Objectives.failure')}</Typography>;
      case 'eval':
        return <Typography className={classes.self} variant="caption">{t('Objectives.evaluate')}</Typography>;
      default:
        return <Typography className={classes.self} variant="caption">{t('Objectives.evaluate')}</Typography>;
    }
  }

  function switchBottomMiddleSelfExpired() {
    switch(objAchieved) {
      case 'true':
        return <Typography className={classes.self} variant="caption">{t('Objectives.timeOver')}</Typography>;
      case 'false':
        return <Typography className={classes.self} variant="caption">{t('Objectives.timeOver')}</Typography>;
      case 'eval':
        return <Typography className={classes.self} variant="caption">{t('Objectives.evaluate')}</Typography>;
      default:
        return <Typography className={classes.self} variant="caption">{t('Objectives.evaluate')}</Typography>;
    }
  }


  function switchTopMiddle() {
    switch(objStatus) {
      case 'pending':
          return null;
      case 'rejected':
          return null;
      case 'accepted':
        return null;
      case 'expired':
        return null;
      case 'archived':
        return null;  
      default:
        return null;
    }
  }

  function switchTopRight() {
    switch(proposer) {
      case 'self':
          return <Tooltip title="Elimina"><IconButton className={classes.borderedIcon} aria-label="delete" size="small" onClick={DeleteObjective}><DeleteIcon className={classes[proposer]}/></IconButton></Tooltip>;
      default:
        return null;
    }
  }

  function switchCenter() {
    switch(objStatus) {
      case 'pending':
        return <span className={classes[proposer]}><TimerIcon className={classes[proposer]} style={{ fontSize: 20 }}/>{objDuration}g</span>;
      case 'rejected':
        return <Typography className={classes.self} variant="caption">{t('Objectives.reason.label')+" "+objComments}</Typography>
      case 'accepted':
        return <span className={classes[proposer]}><TimerIcon className={classes[proposer]} style={{ fontSize: 20 }}/>{(obj_daysRemaining === 0) ? obj_hoursRemaining+"h" : obj_daysRemaining+"g"}</span>;
      case 'expired':
        return (proposer === "partner" ? <Button size="small" color="primary" variant="contained" onClick={ArchiveObjective}>ARCHIVIA</Button> : <HourglassEmptyIcon className={classes[proposer]}/>);
      case 'archived':
        return <HourglassEmptyIcon className={classes[proposer]}/>;  
      default:
        return null;
    }
  }

  return (
    <Card className={classes.root}>
      <div className={classes.main}>

        <div className={classes.spaceBetween}>
          <LetterAvatars initials={objPoints} small="pt" type={proposer}/>

          <Typography className={classes.status} variant="caption">
            <span className={classes[proposer]}>{switchTopMiddle()}</span>
          </Typography>

          <Typography className={classes.status} variant="caption">
            <span className={classes[proposer]}>{switchTopRight()}</span>
          </Typography>

        </div>

        <CardContent className={classes.spaceBetweenColumns}>

          <Typography variant="body2" className={classes.center}>
            {switchCenter()}
          </Typography><br/>

          <Typography className={classes.title} variant="body1">
          <span className={classes[proposer]}>{objTitle}</span>
          </Typography>

          <Typography style={{paddingLeft:15, paddingRight:15}} variant="caption" className={classes[proposer]}>
          {objDescription}
          </Typography>

        </CardContent>

      </div>

      <div >
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={()=>handleClose({newComments:t('Objectives.reason.points')})}>{t('Objectives.reason.points')}</MenuItem>
          <MenuItem onClick={()=>handleClose({newComments:t('Objectives.reason.time')})}>{t('Objectives.reason.time')}</MenuItem>
          <MenuItem onClick={()=>handleClose({newComments:t('Objectives.reason.disagree')})}>{t('Objectives.reason.disagree')}</MenuItem>
        </Menu>
      </div>

      <CardActions className={classes.spaceBetween}>

        <div className={classes[proposer]}>{switchBottomLeft()}</div>
        <div className={classes[proposer]}>{switchBottomMiddle()}</div>
        <div className={classes[proposer]}>{switchBottomRight()}</div>

      </CardActions>

    </Card>
  );
}