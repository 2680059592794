import React from 'react';

import Cover from '../Components/Cover'

import LandingPNG from '../Components/Pictures/landing.jpg'

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LandingCarousel from '../Components/LandingCarousel';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height:'100%',
        flexGrow:1,
    },
    hero: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        width: '100%',
        height: 450,
        backgroundImage: `url(${LandingPNG})`,
        backgroundPosition: 'center',
        alignItems: 'center',
    },
    buttongrid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        margin: theme.spacing(1),
    },
    mybutton: {
        margin: theme.spacing(1),
    },
}));

  function LandingPageRoute () {

    const {t} = useTranslation()

    let history = useHistory();

    const classes = useStyles();
    
    return (
        <Box className={classes.root}>
            <Box className={classes.hero}>
                <Cover/>
                <Box className={classes.buttongrid}>
                    <Button className={classes.mybutton} variant="contained" style={{width: 200, height: 50, fontSize: '1.25rem'}} color="primary" type="submit" onClick={()=>history.push('/signin')}>{t('General.signIn')}</Button>
                </Box>
            </Box>
            <LandingCarousel/>
        </Box>
    );
} 

export default LandingPageRoute;