import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import TopicsGrid from '../Components/TopicsGrid';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        paddingTop: theme.spacing(6),
    },
}));

function ResultsRoute()  {

    const {t} = useTranslation()
  
    const classes = useStyles();

    let history = useHistory();

    return (
        <div className={classes.root}>
            <Button size="small" onClick={()=>history.push('/aggregatedResults')}>{t('General.backToResults')}</Button> 
            <TopicsGrid route="results"/>
        </div>
    );
  }
  
  export default ResultsRoute;