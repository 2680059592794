import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import App from './App';

import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/functions";

import './i18n'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `auth.parliamoneprima.com`,
  databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const functions = window.location.hostname === "localhost" ? 
firebase.functions() :
firebase.app().functions('europe-west1');

if (window.location.hostname === "localhost") {
  firebase.auth().useEmulator('http://localhost:9099/') // Auth emulator
  firebase.firestore().useEmulator("localhost", 8080) // Firestore emulator
  firebase.functions().useEmulator("localhost", 5001) // Functions emulator
} else {
  firebase.analytics();
}

ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();