import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PeopleIcon from '@material-ui/icons/People';
import BuildIcon from '@material-ui/icons/Build';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  mygrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(225px, 1fr))',
    gap: '20px',
    width: '100%',
    maxWidth: 1100,
    margin: 'auto',
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
    position: 'relative',
  },
  icon: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    position: 'absolute',
    top: 0,
    right: 0,
    margin: theme.spacing(1),
  },
}));

export default function AboutRoute() {

  const {t} = useTranslation();

  const classes = useStyles();

  return (
    <Grid className={classes.mygrid}>

      <Paper elevation={3} className={classes.paper}>
      <PeopleIcon className={classes.icon} />
        <Typography color="primary" variant="body2" component="h1">
          <b>{t('About.who.question')}</b>
        </Typography>
        <Typography color="secondary" variant="body2">{t('About.who.answer')}</Typography>
      </Paper>

      <Paper elevation={3} className={classes.paper}>
      <HelpIcon className={classes.icon}/>
        <Typography color="primary" variant="body2" component="h1">
          <b>{t('About.what.question')}</b>
        </Typography>
        <Typography color="secondary" variant="body2">{t('About.what.answer')}</Typography>
      </Paper>

      <Paper elevation={3} className={classes.paper}>
      <BuildIcon className={classes.icon}/>
        <Typography color="primary" variant="body2" component="h1">
          <b>{t('About.why.question')}</b>
        </Typography>
        <Typography color="secondary" variant="body2">{t('About.why.answer')}</Typography>
      </Paper>

      <Paper elevation={3} className={classes.paper}>
      <SettingsIcon className={classes.icon}/>
        <Typography color="primary" variant="body2" component="h1">
          <b>{t('About.how.question')}</b>
        </Typography>
        <Typography color="secondary" variant="body2">{t('About.how.answer')}</Typography>
      </Paper>

    </Grid>
  );
}