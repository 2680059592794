import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationIT from './locales/it/translation.json'

const resources = {
  it: {
    translation: translationIT
  }
}

i18n
.use(initReactI18next) // passes i18n down to react-i18next
.init({
  resources: resources,
  lng: "it",
  fallbackLng: 'it',
  debug: false,
  
  keySeparator: ".",

  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;