import React, { useContext } from 'react';

import LetterAvatars from './LetterAvatars';

import userContext from '../Contexts/userContext'
import objectivesContext from '../Contexts/objectivesContext';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Button, CardActions } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

import firebase from 'firebase/app'
import "firebase/firestore"
import {functions} from '../index'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme)=>({
  root: {
    maxWidth: 500,
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#f7f7f7',
    },
  },
  email: {
    marginBottom: theme.spacing(1),
  },
  media: {
    height: 140,
    cursor: 'default',
  },
  defaultcursor: {
    cursor: 'default',
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  cardActionArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    cursor: 'default',
    padding: 0,
  },
  progressElements: {
    display: 'flex',
  },
}));

export default function PartnerCard({partnerEmail, partnerDisplayName, matchingType, partnerType, partnerProgress}) {

  const {t} = useTranslation()

  const classes = useStyles();

  const userState = useContext(userContext);
  const {activeUser} = userState;
  const {uid, surveyProgress, topicsProgress, displayName} = activeUser

  const objectivesState = useContext(objectivesContext)
  const {objectivesPartner, setObjectivesPartner} = objectivesState

  async function AddPartner() {
    // Quickly update the UI
    firebase.firestore().collection('users').doc(uid).collection('partners').doc(partnerEmail).update({
      matchingType: "matched",
    }) 
    .then(()=>{
      // Updating Firestore
      functions.httpsCallable('addPartner')({
        displayName: displayName,
        partnerEmail: partnerEmail,
        topicsProgress: topicsProgress,
        surveyProgress: surveyProgress
      })
    })
    .then(function(message) {
      // User added successfully, no leed to update the UI as the component will unmount
    })
    .catch(function(error) {
      // The user probably doesn't exist.
    });
  }

  async function DeletePartner() {
    // Quickly update the UI
    (matchingType==="matched" ? 
    firebase.firestore().collection('users').doc(uid).collection('partners').doc(partnerEmail).update({matchingType:"inviter"}) : 
    firebase.firestore().collection('users').doc(uid).collection('partners').doc(partnerEmail).delete())
    .then(()=>{
      // Updating Firestore
      functions.httpsCallable('deletePartner')({
        partnerEmail: partnerEmail,
        topicsProgress: topicsProgress,
        surveyProgress: surveyProgress
      })
    })
    .then(function(message) {
      // User deleted successfully, make sure it is removed from the objectives session in the UI
      if (objectivesPartner.email === partnerEmail) setObjectivesPartner({})
    })
    .catch(function(error) {
      // The user probably doesn't exist.
    });
  }

  async function SubmitPartnerType(checked) {
    // Define variables
    const partnerType = (checked) ? "partner" : "friend"
    // Update Firestore
    firebase.firestore().collection("users").doc(uid).collection("partners").doc(partnerEmail).update({
      partnerType: partnerType,

    })
    .catch(function(error) {
        // The document probably doesn't exist.
    });
  }

  function switchButton() {
      switch(matchingType) {
        case 'invitee':
            return (<Button size="small" color="primary" onClick={DeletePartner}>{t('General.revoke')}</Button>);
        case 'inviter':
            return (<Button size="small" color="primary" onClick={AddPartner}>{t('General.accept')}</Button>);
        case 'matched':
            return (<Button size="small" color="primary" onClick={DeletePartner}>{t('General.revoke')}</Button>);
        default:
            return null;
      }
  }

  function switchText() {
    switch(matchingType) {
      case 'invitee':
          return (partnerEmail);
      case 'inviter':
          return (partnerDisplayName);
      case 'matched':
          return (partnerDisplayName);
      default:
          return null;
    }
  }

  function switchAvatar() {
    switch(matchingType) {
      case 'invitee':
          return (partnerEmail.charAt(0).toUpperCase() + partnerEmail.charAt(1).toUpperCase());
      case 'inviter':
          return (partnerDisplayName.charAt(0).toUpperCase() + partnerDisplayName.charAt(1).toUpperCase());
      case 'matched':
          return (partnerDisplayName.charAt(0).toUpperCase() + partnerDisplayName.charAt(1).toUpperCase());
      default:
          return null;
    }
  }

  function switchSubText() {
    switch(matchingType) {
      case 'invitee':
          return (t('Invites.invitee'));
      case 'inviter':
          return (t('Invites.inviter')+partnerEmail);
      case 'matched':
          return (t('Invites.matched')+partnerEmail);
      default:
          return null;
    }
  }

  const handleChange = (event) => SubmitPartnerType(event.target.checked);
  
  return (
    <Card className={classes.root}>
      <div className={classes.cardActionArea}>
        <div className={classes.cardActions}>
        <LetterAvatars initials={switchAvatar()} type={matchingType}/>
        </div>
        <CardContent className={classes.defaultcursor}>
          <Typography gutterBottom variant="h6" component="h2">
            {switchText()}
          </Typography>
          <Typography className={classes.email} variant="body2" color="textSecondary" component="p">
            {switchSubText()}
          </Typography>
          <Typography variant="caption" color="textSecondary">
          {t('Invites.partner')}
          </Typography>
          <Switch size="small" color="primary" checked={(partnerType==="partner") ? true : false} onChange={handleChange} name="checked" disabled={(matchingType==="inviter") ? true : false}/>
        </CardContent>
      </div>
      <CardActions className={classes.cardActions}>
        {switchButton()}
        {matchingType !== "invitee" ? <Typography color='secondary' variant='caption'>{partnerProgress}{"% "+t('General.complete')}</Typography> : null}
      </CardActions>
    </Card>
  );
}