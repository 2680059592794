import React from 'react';
import PropTypes from 'prop-types';

import {Questions} from '../Functions/Questions'

import SuggestedObjectivesGrid from '../Components/SuggestedObjectivesGrid';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p:3}}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    marginTop:theme.spacing(6),
  },
}));

export default function SuggestedObjectivesRoute() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs 
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="Suggested objectives"
        >
          {Questions.map((topic, topicID) => {
          return <Tab key={topic.label} label={topic.label} {...a11yProps(topicID)} />
          })}
        </Tabs>
      </AppBar>
      <div className={classes.center}>
        {Questions.map((topic, topicID) => {
          return (
            <TabPanel key={topicID} value={value} index={topicID}>
                <SuggestedObjectivesGrid objectives={topic.objectives}/>
            </TabPanel>)
        })}
      </div>
    </div>
  );
}
