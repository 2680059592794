import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { Box, styled } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 150,
  },
  labels: {
    display:'flex',
    justifyContent: "space-between",
    width: '100%',
    marginTop: theme.spacing(1),
  }
}));

const PrettoSlider = styled(Slider)({
  height: 8,
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-valueLabel': {
    left: 'calc(-50% + 4px)',
  },
  '& .MuiSlider-track': {
    height: 8,
    borderRadius: 4,
  },
  '& .MuiSlider-rail': {
    height: 8,
    borderRadius: 4,
  },
});

function valuetext(value) {
  return `${value}%`;
}

export default function QuestionSlider({questionID, value, setValue}) {

  const {t} = useTranslation()

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue((value) => {
      return {...value, [questionID]:newValue}
    })
  }

  function switchValue(value) {
    switch(value) {
      case -2:
        return t('General.no')+"!"
      case -1:
        return t('General.no')+"..."
      case 0:
        return "?"
      case 1:
        return t('General.yes')+"..."
      case 2:
        return t('General.yes')+"!"
      default:
        return null
    }
  }

  return (
    <div className={classes.root}>
      <Box className={classes.labels}>
      <Typography variant="caption" color="primary" id="discrete-slider" gutterBottom>
        {t('Survey.disagree')}
      </Typography>
      <Typography variant="caption" color="primary" id="discrete-slider" gutterBottom>
        {t('Survey.agree')}
      </Typography>
      </Box>
      <PrettoSlider
        value={value}
        onChange={handleChange} 
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        valueLabelFormat={value => switchValue(value)}
        step={1}
        marks
        min={-2}
        max={2}
      />
    </div>
  );
}