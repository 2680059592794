import React from 'react';

import landingSurvey from './Pictures/landingSurvey.png'
import landingInvites from './Pictures/landingInvites.jpg'
import landingResults from './Pictures/landingResults.jpg'
import landingObjectives from './Pictures/landingObjectives.jpg'

import Carousel from 'react-material-ui-carousel'
import { Paper, Typography} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        minWidth: '100%',
        padding: theme.spacing(0),
        margin: 'auto',
        backgroundColor: theme.palette.secondary.contrastText
    },
    heroText: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'center',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginTop: theme.spacing(12),
        marginBottom: theme.spacing(12),
    },
    instructions: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'start',
        margin: 'auto',
        padding:theme.spacing(3)
    },
    instructionCard: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        maxWidth: 250,
        justifyContent: 'start',
        margin: theme.spacing(3)
    },
    top: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        padding: theme.spacing(0),
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: theme.palette.secondary.contrastText
    },
    number: {
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding:theme.spacing(1),
        width: 50,
        height: 50,
        fontSize: theme.typography.h4.fontSize,
        backgroundColor: theme.palette.primary.contrastText,
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
        borderRadius: 15,
        color: theme.palette.primary.main,
    },
    title: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        padding:theme.spacing(1),
        textAlign: 'center',
        justifyContent: 'left',
        width: 'auto',
        fontSize: theme.typography.h4.fontSize,
        color: theme.palette.secondary.main,
    },
    description: {
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'left',
        justifyContent: 'center',
        width: '100%',
        fontSize: theme.typography.h6.fontSize,
        color: theme.palette.secondary.main,
    },
    carousel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        minWidth: '100%',
        padding: theme.spacing(0),
        backgroundColor: theme.palette.common.black
    },
    paper: {
        display: 'flex',
        flexGrow: 1,
        height: 325,
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        minWidth: '100%',
        backgroundColor: theme.palette.secondary.main,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'overlay',
        backgroundPosition: 'left',
        padding: theme.spacing(3),
        color: theme.palette.primary.contrastText
    },
    button: {
        width: '200',
    }
}));

export default function LandingCarousel(props) {

    const {t} = useTranslation()

    const classes = useStyles()

    const items = [
        {
            name: t('HowItWorks.survey.action'),
            description: t('HowItWorks.survey.description'),
            image: landingSurvey
        },
        {
            name: t('HowItWorks.invites.action'),
            description: t('HowItWorks.invites.description'),
            image: landingInvites
        },
        {
            name: t('HowItWorks.results.action'),
            description: t('HowItWorks.results.description'),
            image: landingResults,
        },
        {
            name: t('HowItWorks.objectives.action'),
            description: t('HowItWorks.objectives.description'),
            image: landingObjectives
        },
    ]

    const instructions = [
        {
            title: t('HowItWorks.instructions.1.title'),
            description: t('HowItWorks.instructions.1.description'),
        },
        {
            title: t('HowItWorks.instructions.2.title'),
            description: t('HowItWorks.instructions.2.description'),
        },
        {
            title: t('HowItWorks.instructions.3.title'),
            description: t('HowItWorks.instructions.3.description'),
        },
        {
            title: t('HowItWorks.instructions.4.title'),
            description: t('HowItWorks.instructions.4.description'),
        },
    ]

    return (
        <div className={classes.root}>
            <div className={classes.heroText}>
            <Trans i18nKey="HowItWorks.expectations"><Typography variant="h4">Non innamorarti delle tue</Typography><Typography variant="h4" color="primary">aspettative</Typography></Trans>             
            </div>
            <Carousel className={classes.carousel}
                autoPlay={true}
                timer={500}
                animation="fade"
                indicators={true}
                timeout={500}
                navButtonsAlwaysVisible={false}
                navButtonsAlwaysInvisible={false}
            >
                {
                    items.map( (item, i) => <Item key={i} item={item} /> )
                }
            </Carousel>
            <div className={classes.instructions}>
                {instructions.map((element, index) => 
                <div key={index} className={classes.instructionCard}>
                    <div className={classes.top}>
                        <div className={classes.number}>
                            {index+1}
                        </div>
                        <div className={classes.title}>
                            {element.title}
                        </div>
                    </div>
                    <div className={classes.description}>
                        {element.description}
                    </div>
                </div>
                )}
            </div>
        </div>
    )
}

function Item(props) {

    const classes = useStyles()

    return (
        <Paper className={classes.paper} style={{backgroundImage: `url(${props.item.image})`,
    }}>
        <Typography variant="h4">{props.item.name}</Typography>
        <Typography variant="h5">{props.item.description}</Typography>
        </Paper>
    )
}