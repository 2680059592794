import React, { useContext } from 'react';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import userContext from '../../Contexts/userContext'

import {Questions} from '../../Functions/Questions'

import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import firebase from 'firebase/app'
import {functions} from '../../index'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles( (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
    },
  }));

export default function AddPartner() {

  const {t} = useTranslation()

  const [open, setOpen] = React.useState(false);

  const classes=useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const userState = useContext(userContext);
  const { activeUser } = userState;
  const { uid, topicsProgress, surveyProgress, displayName } = activeUser

  return (
    <div>
      <Button variant="contained" size="large" color="primary" onClick={handleClickOpen}>
      {t('General.add')+" Partner"}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

        <DialogTitle id="form-dialog-title">{t('General.invite')+" Partner"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span>{t('Invites.invitesDialog')}</span>
          </DialogContentText>
          <Formik
            initialValues={{ email: '' }}
            
            validationSchema={Yup.object({
                email: Yup.string()
                .email(t('Validation.email'))
                .required(t('Validation.required'))
                .notOneOf([activeUser.email, null], t('Validation.noSelf')),
            })}
            
            onSubmit = { (values, {setSubmitting}) => {
              const partnerEmail = values.email
              // Quickly update the UI
              firebase.firestore().collection('users').doc(uid).collection('partners').doc(partnerEmail).set({
                matchingType: "invitee",
                partnerType: "partner",
                uid: "",
                email: partnerEmail,
                displayName: "",
                surveyProgress: 0,
                topicsProgress: Array(Questions.length).fill(0)
              }) 
              .then(()=>{
                // Set the UI as available before launching back-end scripts
                setSubmitting(false)
                handleClose();
                // Updating Firestore
                functions.httpsCallable('addPartner')({
                  displayName: displayName,
                  partnerEmail: partnerEmail,
                  topicsProgress: topicsProgress,
                  surveyProgress: surveyProgress
                })
              })
              .then(function() { 
                // Set the UI
                // setSubmitting(false);
                // handleClose();
              })
              .catch(function(error) {
                // The user probably doesn't exist.
                // Set the UI
                setSubmitting(false);
              });
            }}
            >
            {formik => {
              const { handleSubmit } = formik;
              return (
                <Form className={classes.root} onSubmit={handleSubmit}>
                    <Box className={classes.root}>
                        <Field style={{width:'100%', marginTop:10, marginBottom:10}} component={TextField} label="Email" name="email" type="text"/>
                        <DialogActions>
                          <Button type="button" onClick={handleClose} color="secondary">
                            {t('General.cancel')}
                          </Button>
                          <Button type="submit" color="primary" disabled={formik.isSubmitting}>
                              {formik.isSubmitting ? <CircularProgress/> : t('General.invite')}
                          </Button>
                        </DialogActions>
                    </Box>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}