import React, { useContext } from 'react';

import resultsContext from '../Contexts/resultsContext'

import { PieChart } from 'react-minimal-pie-chart';

import { Questions } from '../Functions/Questions';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { CircularProgress, useTheme } from '@material-ui/core';
import userContext from '../Contexts/userContext';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme)=>({
  root: {
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "space-between",
    cursor: "default",
    '&:hover': {
      backgroundColor: '#f7f7f7',
    },
  },
  cardcontent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: "default",
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    cursor: "default",
  },
  mycircle: {
    position: "relative",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progresstext: {
    position: "absolute",
    top:"50%",
    left:"50%",
    transform: "translate(-50%,-50%)",
  },
  labels: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: "default",
  },
  questions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: '0px',
    cursor: "default",
  },
  center: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: "default",
  },
  pie: {
    padding: theme.spacing(3),
    cursor: "default",
  },
  withMargins: {
    marginRight: theme.spacing(1),
    cursor: "default",
  },
}));

export default function TopicCardDetailedResults({topic}) {

  const {t} = useTranslation()

  const theme = useTheme();
  
  const classes = useStyles();

  // Define and access the correct set of results for a given partner and partnerType
  const resultsState = useContext(resultsContext);
  const {resultsPartner} = resultsState;

  const userState = useContext(userContext)
  const {activePartners} = userState
  const partner = activePartners.find(partner => partner.email === resultsPartner.email) || {}
  const {partnerType, topicsProgress} = partner
  const partnerResults = partner.results[partnerType]  
  const {detailedResultsObject, criticalQuestions, disalignedQuestions, surveyLength} = partnerResults.detailedResults
  
  // Define and access the correct set of questions related to the results
  const allQuestions = 
  Questions.map((topic) => {
      return topic.questions.map((question) => {
          return question.text
      })
  })
  const friendsQuestions = 
  Questions.map((topic, topicID) => {
      return topic.questions.filter((question, questionID) => {
          return Questions[topicID].questions[questionID].partnerType==="all"
      }).map((question)=> {
          return question.text
      })
  })
  const detailedQuestions = (partnerType === "partner") ? allQuestions : friendsQuestions

  // Define the variables needed for the UI
  let detailedResults = [...Array(detailedQuestions.length)].map((topic, topicID) => (Array(detailedQuestions[topicID].length).fill("")));
  detailedQuestions.forEach((topic, topicID) => { topic.forEach((question, questionID) => {
      detailedResults[topicID][questionID] = detailedResultsObject[`${topicID}-${questionID}`]
  })})

  // Calculate pie chart data
  let data=[];
  if (criticalQuestions[topic.id] !== 0) {data.push({ title: t('General.questions')+" "+t('Results.critical'), value: criticalQuestions[topic.id], color: theme.palette.secondary.main })}
  if (disalignedQuestions[topic.id] !== 0) {data.push({ title: t('General.questions')+" "+t('Results.disaligned'), value: disalignedQuestions[topic.id], color: theme.palette.primary.dark})}
  if (surveyLength[topic.id] - disalignedQuestions[topic.id] - criticalQuestions[topic.id] !== 0) {data.push({ title: t('General.questions')+" "+t('Results.aligned'), value: surveyLength[topic.id] - disalignedQuestions[topic.id] - criticalQuestions[topic.id], color: theme.palette.primary.light })}

  function getCriticalQuestions() {
    if (criticalQuestions[topic.id] !== 0) {
      return (
        <ul style={{marginLeft:'0px', paddingLeft:theme.spacing(3)}}>
          {detailedQuestions[topic.id].map((question, questionID) => {
            if (detailedResults[topic.id][questionID] === "critical") {
              return <li key={topic.id+questionID} variant="caption" style={{color:theme.palette.secondary.main}}>{question}</li>
            }
            return null
          })}
        </ul>
      )
    }
    return null;
  }

  function getDisalignedQuestions() {
    if (disalignedQuestions[topic.id] !== 0) {
      return (
        <ul style={{marginLeft:'0px', paddingLeft:theme.spacing(3)}}>
          {detailedQuestions[topic.id].map((question, questionID) => {
            if (detailedResults[topic.id][questionID] === "disaligned") {
              return <li key={topic.id+questionID} variant="caption" style={{color:theme.palette.primary.dark}}>{question}</li>
            }
            return null
          })}
        </ul>
      )
    }
    return null;
  }

  return (
    <Card className={classes.root}>
      <div>
      <CardContent className={classes.center}>
          <Typography gutterBottom variant="h6" component="h2">
            {topic.label} 
          </Typography>
          <div className={classes.mycircle}>
            <CircularProgress variant="determinate" size={40} value={topicsProgress[topic.id]}/>  
            <Typography variant="body2" color="primary" className={classes.progresstext}>{topicsProgress[topic.id]}%</Typography>
          </div>          
        </CardContent>
        <CardContent className={classes.pie}>
          <PieChart
            data={data}
            startAngle={-170}
            lengthAngle={160}
            totalValue={surveyLength[topic.id]}
            paddingAngle = {15}
            rounded = {true}
            animate = {true}
            lineWidth = {10}
            viewBoxSize={[100, 50]}
            label={({ dataEntry }) => dataEntry.value}
            labelStyle={(dataEntry) => ({
              fill: dataEntry.color,
              fontSize: '8px',
              fontFamily: 'sans-serif',
            })}
            labelPosition={70}
          />
        </CardContent>
        <CardContent className={classes.cardcontent}>
          <Typography className={classes.center} variant="body1">
          {t('HowTo.results.title')}
          </Typography>
          <Typography className={classes.labels} variant="caption">
            <span style={{color:theme.palette.secondary.main}}>{t('Results.critical')}</span> 
            <span style={{color:theme.palette.primary.dark}}>{t('Results.disaligned')}</span> 
            <span style={{color:theme.palette.primary.light}}>{t('Results.aligned')}</span>
          </Typography>
        </CardContent>
        <CardContent className={classes.cardcontent}>
          <Typography className={classes.questions} variant="caption">
            {getCriticalQuestions()}
            {getDisalignedQuestions()}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
}