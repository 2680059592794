import React, {useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch} from 'react-router-dom';

import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Navigator from '../Components/Navigator';

import ProfileRoute from './ProfileRoute';
import SurveyRoute from './SurveyRoute';
import InvitesRoute from './InvitesRoute';
import AggregatedResultsRoute from './AggregatedResultsRoute';
import DetailedResultsRoute from './DetailedResultsRoute';
import SurveyHomeRoute from './SurveyHomeRoute';
import HomePageRoute from './HomePageRoute';
import ObjectivesRoute from './ObjectivesRoute';
import SuggestedObjectivesRoute from './SuggestedObjectivesRoute';

import userContext from '../Contexts/userContext';
import resultsContext from '../Contexts/resultsContext';
import objectivesContext from '../Contexts/objectivesContext';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import firebase from 'firebase/app'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        heigth:'100%',
        flexGrow: 1
    },
}));

export default function SignedInRoute()  {

    const classes=useStyles();
    
    const userState = useContext(userContext);
    const {activeListeners, activePartners, /* activeSubscription, */ setActiveListeners, activeUser, setActiveObjectives} = userState;
    const {uid, email} = activeUser
  
    const [resultsPartner, setResultsPartner] = useState({})
    const [objectivesPartner, setObjectivesPartner] = useState({});

    useEffect(()=>{
        // Record the last time the user has used the app
        firebase.firestore().collection('users').doc(uid).update({
            lastLogin: firebase.firestore.FieldValue.serverTimestamp()
        })
    },[uid])

    useEffect(()=>{
        // If user only has one partner, set it as the default one for objectives
        const matchedPartners = activePartners.filter(partner => partner.matchingType === "matched") 
        if (matchedPartners.length === 1) setObjectivesPartner(matchedPartners[0])
    },[activePartners])

    useEffect(()=>{
        // Manage objectives listeners and state

        //Clearing up old listeners
        if (activeListeners.unsubscribeObjectivesListener) {
          activeListeners.unsubscribeObjectivesListener.user()
          activeListeners.unsubscribeObjectivesListener.partner()
        }
      
        // Set objectives listeners
        const partnerUid = objectivesPartner.uid
        const partnerEmail = objectivesPartner.email

        if (partnerUid && partnerEmail) {
          const unsubscribeObjectivesListener = {}
    
          // Set listener for objectives proposed by the current user
          const user = firebase.firestore().collection('users').doc(uid).collection('partners').doc(partnerEmail).collection('objectives').onSnapshot(function(querySnapshot) {
            let userObjectives = []
            // Retrieving data from Firestore
            querySnapshot.forEach(function(doc) {
              userObjectives.push({...doc.data({ serverTimestamps: 'estimate' }), proposer: 'self'});
            })
            // Setting the UI
            setActiveObjectives((state) => { return {...state, user:userObjectives}})
          }, function(error) {
          })
    
          // Set listener for objectives proposed by the partner
          const partner = firebase.firestore().collection('users').doc(partnerUid).collection('partners').doc(email).collection('objectives').onSnapshot(function(querySnapshot) {
            let partnerObjectives = []
            // Retrieving data from Firestore
            querySnapshot.forEach(function(doc) {
              partnerObjectives.push({...doc.data({ serverTimestamps: 'estimate' }), proposer: 'partner'});
            })
            // Setting the UI
            setActiveObjectives((state) => { return {...state, partner:partnerObjectives}})
          }, function(error) {
          })
     
          // Setting up the clearers
          unsubscribeObjectivesListener.user = user
          unsubscribeObjectivesListener.partner = partner
    
          // Setting the clearer
          setActiveListeners({...activeListeners, unsubscribeObjectivesListener:unsubscribeObjectivesListener})
        }
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[objectivesPartner])
     
    const resultsState = {
        resultsPartner: resultsPartner,
        setResultsPartner: setResultsPartner,
    }

    const objectivesState = {
        objectivesPartner: objectivesPartner,
        setObjectivesPartner: setObjectivesPartner,
    }

    const switchSurvey = () => {
        return (
            <Switch>

                <Route path="/" exact>
                    <HomePageRoute/>
                </Route>

                <Route path="/profile" exact>
                    <ProfileRoute/>
                </Route>
                
                <Route path="/survey" exact>
                    <SurveyHomeRoute/>
                </Route>

                <Route path={'/survey/:topicID'}>
                    <SurveyRoute/>
                </Route>

                <Route path={'/invites'}>
                    <InvitesRoute/>
                </Route>

                <Route path={'/aggregatedResults'}>
                    <AggregatedResultsRoute/>
                </Route>

                <Route path={'/detailedResults'}>
                    {(true /* || activeSubscription.subscribed || activeSubscription.hasGift */ )  ? <DetailedResultsRoute/> : <Redirect to={{state: {referrer:'/aggregatedResults'}, pathname:"/pricing"}}/>} {/* Premium version: remove "|| true"*/}
                </Route>

                <Route path={'/objectives'}>
                    {(true /* || activeSubscription.subscribed || activeSubscription.hasGift */ ) ? <ObjectivesRoute/> : <Redirect to={{state: {referrer:'/objectives'}, pathname:"/pricing"}}/>} {/* Premium version: remove "|| true"*/}
                </Route>

                <Route path={'/suggestedObjectives'}>
                    {(true /* || activeSubscription.subscribed || activeSubscription.hasGift */ ) ? <SuggestedObjectivesRoute/> : <Redirect to={{state: {referrer:'/suggestedObjectives'}, pathname:"/pricing"}}/>} {/* Premium version: remove "|| true"*/}
                </Route>

                <Route path="*">
                    <Redirect to="/"/>
                </Route>
                
            </Switch>
        )
    }

    return (
        <resultsContext.Provider value={resultsState}>
            <objectivesContext.Provider value={objectivesState}>
                <Box className={classes.root}>
                    <Header/>
                    <Navigator/>
                    <div className={classes.body}>{switchSurvey()}</div>
                    <Footer/>        
                </Box>
            </objectivesContext.Provider>
        </resultsContext.Provider>
    );
  }
  