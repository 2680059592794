import React, { useContext, useEffect } from 'react';

import userContext from '../Contexts/userContext';

import QuestionSlider from '../Components/QuestionSlider';
import {Questions} from '../Functions/Questions';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from 'react-router-dom';

import firebase from "firebase/app";
import "firebase/firestore";
import { useTranslation } from 'react-i18next';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    margin: 'auto',
    alignItems: 'center',
    padding: theme.spacing(4),
    maxWidth: 750,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'start',
    height: 50,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    height: 50,
    marginBottom: theme.spacing(1),
  },
  question: {
    height: 'auto',
    minWidth: 200,
    overflow: 'hidden',
    display: 'block',
    textAlign: 'center',
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  stepperButtons: {
      width: '100%',
      backgroundColor: theme.palette.primary.contrastText,
  }
}));

export default function SurveyRoute() {

  const {t} = useTranslation()

  const topicID = parseInt(useParams().topicID)

  const userState = useContext(userContext);
  const {activeUser, activeSurvey} = userState
  const {uid} = activeUser;

  let history = useHistory();

  let initialValue = {}
  Questions[topicID].questions.forEach((question, questionID) => {
    initialValue[questionID] = (activeSurvey[`${topicID}-${questionID}`]) ? activeSurvey[`${topicID}-${questionID}`].value : 0 // If the page reloads the survey is not available immediately
  })

  const [value, setValue] = React.useState(initialValue)

  useEffect(() => {
    let surveyValue = {}
    Questions[topicID].questions.forEach((question, questionID) => {
      surveyValue[questionID] = (activeSurvey[`${topicID}-${questionID}`]) ? activeSurvey[`${topicID}-${questionID}`].value : 0
    })
    window.scrollTo(0, 0)
    setValue(surveyValue)
  },[activeSurvey, topicID])

  const classes = useStyles();

  const handleSave = () => {
    let surveyData = {}
    Questions[topicID].questions.forEach((question, questionID) => {
      surveyData[`${topicID}-${questionID}`] = {
        topicID: topicID,
        questionID: questionID,
        value: value[questionID],
        completed: true,
      }
    })
    firebase.firestore().collection("surveys").doc(uid).update(surveyData)
    .then(function() {
      const nextSurvey = (topicID === Questions.length - 1) ? '/survey' : '/survey/'+(topicID+1)
      history.push(nextSurvey)
    })
    .catch(function(error) {
    });  
  };

  return (
    <Paper elevation={3} className={classes.root}>
      <Paper square elevation={3} className={classes.header}>
        <Typography variant="h6">{Questions[topicID].label}</Typography>
      </Paper>
      {Questions[topicID].questions.map((question, questionID) => {
        return <div style={{width:'100%'}} key={questionID}>
          <Typography variant="body1" className={classes.question}>
          {question.text}
          </Typography>
          <QuestionSlider questionID={questionID} value={value[questionID] || 0 /* if the new topic has more questions than the previous the first render does not a defined value for all questions */} setValue={setValue}/>
          <Divider/>
        </div>
      })
      }
      <Button style={{marginTop:15}} variant="contained" onClick={handleSave}>{t('General.save')}</Button>
    </Paper>
  );
}