import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Box, Button, CardActions, CardMedia, CircularProgress } from '@material-ui/core';
import userContext from '../Contexts/userContext';

import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme)=>({
  root: {
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "space-between",
    cursor:"pointer",
    '&:hover': {
      backgroundColor: '#f7f7f7',
    },
  },
  media: {
    height: 140,
    width:'100%',
    cursor: 'pointer',
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  progressElements: {
    display: 'flex',
  },
  withMargins: {
    marginRight: theme.spacing(1),
  },
}));

export default function TopicCardSurvey({topic}) {

  const classes = useStyles();

  let history = useHistory()

  const topicID = topic.id
  const userState = useContext(userContext);
  const {activeUser} = userState;
  
  // Calculating progress percentage for this topic  
  const topicProgressPercent = activeUser.topicsProgress[topicID]

  return (
    <Card className={classes.root} onClick={() => history.push('/survey/'+topicID)}>
      <div>
        <CardMedia
          className={classes.media}
          image={topic.imagePNG}
          title={topic.label}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {topic.label}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {topic.description}
          </Typography>
        </CardContent>
    </div>
    <CardActions className={classes.cardActions}>
      <Button size="small" color="primary">
        {topicProgressPercent === 0 ? "Inizia" : "Continua"}
      </Button>
      <Box className={classes.progressElements}>
        <span className={classes.withMargins}>{Math.round(topicProgressPercent)+"%"}</span>
        <CircularProgress variant="determinate" size={40} value={topicProgressPercent} />
      </Box>
    </CardActions>
  </Card>
  );
}