import React, {useContext} from 'react';
import Switch from '@material-ui/core/Switch';

import userContext from '../Contexts/userContext';

import { makeStyles, Typography } from '@material-ui/core';
import SetNotificationsPermissions from '../Functions/SetNotificationsPermissions';

import firebase from 'firebase/app'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles( (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin:'auto',
        width: '350px',
    },
    switch: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop:theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
  }));

export default function NotificationSwitch() {

    const {t} = useTranslation()

    const classes = useStyles();

    const initialNotificationState = (Notification.permission === "granted") ? true : false
    const [notificationState, setNotificationState] = React.useState(initialNotificationState);

    const userState = useContext(userContext)
    const {activeUser} = userState

    const handleChange = (event) => {
    setNotificationState(event.target.checked);
    SetNotificationsPermissions({activeUser, firestore:firebase.firestore(), notificationState:event.target.checked})
    };

    return (
        <div className={classes.root}>
            <Typography color="primary" variant="body2">{t('Notifications.action')}</Typography>
            <div className={classes.switch}>
                {notificationState ? <Typography color="primary" variant="body2">{t('Notifications.statusOK')}</Typography> : <Typography color="secondary" variant="body2">{t('Notifications.statusKO')}</Typography>}
                <Switch
                checked={(Notification.permission === "denied" || !("Notification" in window)) ? false : notificationState}
                onChange={handleChange}
                color="primary"
                disabled={(Notification.permission === "denied" || Notification.permission === "granted" || !("Notification" in window)) ? true : false}
                name="checkedA"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </div>
            {!("Notification" in window) ? <Typography color="primary" variant="body2">{t('Notifications.unavailable')}</Typography> : null}
            {Notification.permission === "denied" ? <Typography color="primary" variant="body2">{t('Notifications.blocked')}<br/>
            Settings {'>'} Privacy and Security {'>'} View permissions and data stored across sites {'>'} parliamoneprima.com {'>'} Allow</Typography> : null}
        </div>
    );
}