import React from 'react';

import Logo from './Logo';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    logocontainer: {
      padding: theme.spacing(2),
    },
    subtitle: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    }
}));

function Cover () {

    const { t } = useTranslation();
    
    const classes = useStyles();

    return(
        <Box>
            <Box className={classes.logocontainer}>
                <Logo size='largest'/>
            </Box>
            <Typography variant='h5' className={classes.subtitle}>
                {t("Branding.tagline")}            
            </Typography>
        </Box>
    );
}

export default Cover;