import Sex from '../Components/Pictures/Sex.webp';
import Trust from '../Components/Pictures/Trust.webp';
import Children from '../Components/Pictures/Children.webp';
import Career from '../Components/Pictures/Career.webp';
import Family from '../Components/Pictures/Family.webp';
import House from '../Components/Pictures/House.webp';
import Hobbies from '../Components/Pictures/Hobbies.webp';
import Wealth from '../Components/Pictures/Wealth.webp';
import Friends from '../Components/Pictures/Friends.webp';
import Communication from '../Components/Pictures/Communication.webp';
import Attractiveness from '../Components/Pictures/Attractiveness.webp';
import Spirituality from '../Components/Pictures/Spirituality.webp';

import SexPNG from '../Components/Pictures/Sex.jpg';
import TrustPNG from '../Components/Pictures/Trust.jpg';
import ChildrenPNG from '../Components/Pictures/Children.jpg';
import CareerPNG from '../Components/Pictures/Career.jpg';
import FamilyPNG from '../Components/Pictures/Family.jpg';
import HousePNG from '../Components/Pictures/House.jpg';
import HobbiesPNG from '../Components/Pictures/Hobbies.jpg';
import WealthPNG from '../Components/Pictures/Wealth.jpg';
import FriendsPNG from '../Components/Pictures/Friends.jpg';
import CommunicationPNG from '../Components/Pictures/Communication.jpg';
import AttractivenessPNG from '../Components/Pictures/Attractiveness.jpg';
import SpiritualityPNG from '../Components/Pictures/Spirituality.jpg';

import {functions} from '../index'

const Images = {
    Sex: Sex,
    Trust: Trust,
    Children: Children,
    Career: Career,
    Family: Family,
    House: House,
    Hobbies: Hobbies,
    Wealth: Wealth,
    Friends: Friends,
    Communication: Communication,
    Attractiveness: Attractiveness,
    Spirituality: Spirituality
}

const ImagesPNG = {
    Sex: SexPNG,
    Trust: TrustPNG,
    Children: ChildrenPNG,
    Career: CareerPNG,
    Family: FamilyPNG,
    House: HousePNG,
    Hobbies: HobbiesPNG,
    Wealth: WealthPNG,
    Friends: FriendsPNG,
    Communication: CommunicationPNG,
    Attractiveness: AttractivenessPNG,
    Spirituality: SpiritualityPNG
}

export let Questions = []

export async function initializeQuestions() {
    let textQuestions = []
    // Retrieving survey from back-end
    const getTextQuestions = functions.httpsCallable('getTextQuestions');
    return getTextQuestions()
    .then(function(result) {
        // Read result of the Cloud Function.
        textQuestions = result.data;
        textQuestions.forEach((textTopic, topicID) => {
            const imageLabel = textTopic.image
            Questions[topicID] = textTopic
            Questions[topicID].id = topicID;
            Questions[topicID].image = Images[imageLabel]; 
            Questions[topicID].imagePNG = ImagesPNG[imageLabel]; 
            Questions[topicID].questions.forEach((question, questionID) => {
                Questions[topicID].questions[questionID].id = questionID;
            })
        })
        return Questions
    });
}