import React, {useContext} from 'react';

import snackBarContext from '../Contexts/snackBarContext'
import authContext from '../Contexts/authContext';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress, makeStyles } from '@material-ui/core';

import {functions} from '../index'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles( (theme) => ({
  root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin:'auto',
      width: '350px',
  },
}));

export default function DeleteAccount() {

  const {t} = useTranslation()

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false)

  const authState = useContext(authContext);
  const { onSignOut } = authState;

  const snackBarState = useContext(snackBarContext);
  const { setSnackBarOpen, setSnackBarMessage } = snackBarState;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteAccount = () => {
    setLoading(true)
    functions.httpsCallable('deleteUser')()
    .then(function(message) {
      // Set the UI
      setLoading(true)
      onSignOut()
      setSnackBarOpen(true);
      setSnackBarMessage('accountDeleted')
    })
    .catch(function(error) {
        // The user probably doesn't exist.
        setLoading(true)
    })
  }

  return (
    <div className={classes.root}>
      <Button style={{width:'100%'}} variant='outlined' color='primary' size='large' onClick={handleClickOpen}>
        {t('General.delete')+" Account"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('Profile.deleteTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {t('Profile.deleteDialog')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annulla
          </Button>
          <Button onClick={handleDeleteAccount} color="secondary" disabled={loading}>
            {loading ? <CircularProgress/> : t('General.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}