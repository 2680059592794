import { createMuiTheme } from '@material-ui/core/styles';

function createTheme() {
    return createMuiTheme({
        palette: {
        text: {
            primary: '#505050',
        },
        primary: {
            main: '#f06292',
        },
        secondary: {
            main: '#505050',
        },
        },
        typography: {
        fontFamily: [
            'Candara, Futura, Calibri, Segoe, Segoe UI, Optima, Arial, sans-serif!important',
        ].join(','),
        },
    });
}

export default createTheme;