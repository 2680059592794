import React, {useContext, useEffect} from 'react';
import { useHistory } from 'react-router-dom';

import Logo from '../Components/Logo';
import DevelopmentSignIn from '../Components/DevelopmentSignIn';

import snackBarContext from '../Contexts/snackBarContext';
import authContext from '../Contexts/authContext';

import { makeStyles } from '@material-ui/core';

import firebase from "firebase/app";
import * as firebaseui from 'firebaseui/dist/npm__it'
import "firebase/auth";
import "firebase/firestore";
import 'firebaseui/dist/firebaseui.css'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles( (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '350px',
        margin: 'auto',

    },
  }));

function SignInRoute() {

  const {t} = useTranslation()

  var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth())

  const classes = useStyles(); 

  const snackBarState = useContext(snackBarContext);
  const {setSnackBarOpen, setSnackBarMessage} = snackBarState;

  const authState = useContext(authContext);
  const {setJustRegistered, onSignOut} = authState;

  let history = useHistory();

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: async function(authResult) {
        const user = authResult.user
        const signInMethod = authResult.additionalUserInfo.providerId

        if (authResult.additionalUserInfo.isNewUser) {
          setJustRegistered(true) // Reinitialize UI showing loader
          // Update displayName in Firestore (not available in back-end at the time of creation)
          await firebase.firestore().collection("users").doc(user.uid).set({
            displayName: user.displayName,
            email: user.email,
            uid: user.uid
          }, { merge: true })
          // Handling UI messages to new email/password user and updating missing fields in Firestore
          if (!user.emailVerified && signInMethod === "password") {
            user.sendEmailVerification() // TODO In back-end: send nicer looking email
            .then(()=>{
              // Display UI confirming user creation
              setSnackBarOpen(true);
              setSnackBarMessage('accountCreated')
              onSignOut()
            })
            .catch(error => {
            // An error happened.
            setJustRegistered(false)
            })
          }

          // Facebook.com users email verified in back-end, updating the UI
          if (signInMethod === "facebook.com") {
            firebase.auth().currentUser.reload().then(()=>{
              setJustRegistered(false)
              history.push('/')
            })
          } else {
          setJustRegistered(false)
          history.push('/')
          }
        } else {
          if (user.emailVerified === false) {
              setSnackBarOpen(true);
              setSnackBarMessage('emailNotVerified')
              user.sendEmailVerification().then(function() {
                // Email sent.
              }).catch(function(error) {
                // An error happened.
              });
            }
        }
        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        return false;
      },
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    // signInSuccessUrl: process.env.REACT_APP_FRONTEND_HOST,
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //   firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    //   firebase.auth.GithubAuthProvider.PROVIDER_ID,
    //   firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],
    // Terms of service url.
    tosUrl: process.env.REACT_APP_FRONTEND_HOST+'/termsconditions',
    // Privacy policy url.
    privacyPolicyUrl: process.env.REACT_APP_FRONTEND_HOST+'/privacypolicy'
  };

  useEffect(()=>{
    if (window.location.hostname !== "localhost") {
      ui.start('#firebaseui-auth-container', uiConfig)
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  return (
  <div className={classes.root}>
    <Logo size="largest"/>
    <p>{t('General.continue')}</p>
    {(window.location.hostname !== "localhost") ? 
    <div id="firebaseui-auth-container"></div> : 
    <DevelopmentSignIn/>
    }
  </div>
  );
};

export default SignInRoute;