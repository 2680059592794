import React, {useContext} from 'react';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import authContext from '../Contexts/authContext';
import snackBarContext from '../Contexts/snackBarContext';

import { TextField } from 'formik-material-ui';

import Button from '@material-ui/core/Button';
import { Box, makeStyles, Typography } from '@material-ui/core';

import firebase from "firebase/app";
import "firebase/auth";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles( (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin:'auto',
        width: '350px',
    },
    terms: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
    },
    formControl: {
        width: '100%',
        marginBottom: theme.spacing(2)
      },
    myCheckBox: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
    },
    left: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        width: '100%'
    },
    dark: {
        color: theme.palette.error.main
    }
  }));

export default function UpdatePassword() {

    const {t} = useTranslation()

    const classes = useStyles(); 

    const authState = useContext(authContext);
    const {onSignOut} = authState;    

    const snackBarState = useContext(snackBarContext);
    const { setSnackBarOpen, setSnackBarMessage } = snackBarState;

    return (
 
        <Formik
        initialValues={{ newPassword: '', confirmPassword: ''}}

        validationSchema={Yup.object({
            newPassword: Yup.string()
            .matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){0})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            t('Validation.password'))
            .required(t('Validation.required')),
            confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], t('Validation.samePassword'))
            .required(t('Validation.required')),
        })}

        onSubmit = { (values, {setSubmitting}) => {
            const user = firebase.auth().currentUser;        
            user.updatePassword(values.newPassword).then(function() {
                setSnackBarOpen(true);
                setSnackBarMessage('passwordModified')
                setSubmitting(false);
            }).catch(function(error) {
                setSnackBarOpen(true);
                setSnackBarMessage('reauthenticate')
                setSubmitting(false);
                onSignOut()
            });
        }}            >
            <Form className={classes.root}>
                <Box className={classes.root}>

                    <Typography color="primary" variant="body1" style={{marginTop:5, marginBottom:15}}>{t('General.edit')+" password"}</Typography>

                    <Field style={{width:'100%', marginTop:10, marginBottom:10}} component={TextField} label={t('Profile.newPassword')} name="newPassword" type="password"/>

                    <Field style={{width:'100%', marginTop:10, marginBottom:10}} component={TextField} label={t('Profile.confirmPassword')} name="confirmPassword" type="password"/>

                    <Button variant="contained" size="large" color="primary" type="submit" style={{marginTop: "1rem", marginBottom: "1rem", width: '100%'}}>
                    {t('General.edit')}
                    </Button>
                </Box>
            </Form>
        </Formik>

  );
};