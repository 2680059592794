import React, { useEffect, useState} from 'react';
import { Redirect, Route, Switch, useHistory} from 'react-router-dom';

import SignInRoute from './Routes/SignInRoute';
import SignedInRoute from './Routes/SignedInRoute';
import LandingPageRoute from './Routes/LandingPageRoute';

import authContext from './Contexts/authContext';
import userContext from './Contexts/userContext';
import snackBarContext from './Contexts/snackBarContext';

import SnackBarMessages from './Components/Modals/SnackBarMessages';
import Header from './Components/Header';
import Footer from './Components/Footer';

import AboutRoute from './Routes/AboutRoute';
import TermsConditionsRoute from './Routes/TermsConditionsRoute';
import PrivacyPolicyRoute from './Routes/PrivacyPolicyRoute';
import HowToRoute from './Routes/HowToRoute';
// import PricingRoute from './Routes/PricingRoute'; // TO DO Subscription version: remove comments

import createTheme from './Functions/CreateTheme';
import { SubscribeUserToPush } from './Functions/SetNotificationsPermissions';
import { Questions, initializeQuestions } from './Functions/Questions';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

import './App.css'

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  progress: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
}))

function App()  {

  const classes = useStyles();

  const [isSignedIn, setSignIn] = useState(false);
  const [authd, setAuthd] = useState(false);
  const [justRegistered, setJustRegistered] = useState(false);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const [activeUser, setActiveUser] = useState('');
  const [activeSurvey, setActiveSurvey] = useState({});
  const [activePartners, setActivePartners] = useState([]);
  const [activeObjectives, setActiveObjectives] = useState({user:[], partner:[]});
  // const [activeSubscription, setActiveSubscription] = useState({
  //   subscribed: false,
  //   giftUser: {
  //     email:"",
  //     uid: "",
  //     displayName:""
  //   },
  //   hasGift: false
  // });
  const [activeListeners, setActiveListeners] = useState({
    unsubscribeUserListener: null,
    unsubscribeSurveyListener: null,
    // unsubscribeSubscriptionListener: null, // Subscription version: remove comments
    unsubscribePartnersListener: null,
    unsubscribeObjectivesListener: null,
  })

  let history = useHistory();
  const theme = createTheme();

  useEffect(() => {
    // Initialize questions for UI
    if (Questions.length === 0) {
      initializeQuestions();
    }
  },[])

  async function onSignOut() {
    // Clearing up old listeners
    if (activeListeners.unsubscribeUserListener) activeListeners.unsubscribeUserListener()
    if (activeListeners.unsubscribePartnersListener) activeListeners.unsubscribePartnersListener()
    if (activeListeners.unsubscribeSurveyListener) activeListeners.unsubscribeSurveyListener()
    // if (activeListeners.unsubscribeSubscriptionListener) activeListeners.unsubscribeSubscriptionListener() // Subscription version: remove comments
    if (activeListeners.unsubscribeObjectivesListener) activeListeners.unsubscribeObjectivesListener.user()
    if (activeListeners.unsubscribeObjectivesListener) activeListeners.unsubscribeObjectivesListener.partner()

    // Signing out from Firebase
    firebase.auth().signOut()

    // Resetting the UI
    setSignIn(false);
    setJustRegistered(false)
    setActiveUser('')
    setActiveSurvey({})
    setActivePartners([])
    setActiveObjectives({user:[], partner:[]})
    // setActiveSubscription({
    //   subscribed: false,
    //   giftUser: {
    //     email:"",
    //     uid: "",
    //     displayName:""
    //   },
    //   hasGift: false
    // });
    setActiveListeners({
      unsubscribeUserListener: null,
      unsubscribeSurveyListener: null,
      // unsubscribeSubscriptionListener: null, // Subscription version: remove comments
      unsubscribePartnersListener: null,
      unsubscribeObjectivesListener: null,
    })
    history.push("/")
  }

  async function onSignInFromToken({firebaseAuthUser}) {
    // Defining variables
    const {uid} = firebaseAuthUser

    // Define firestore references
    const userRef = firebase.firestore().collection("users").doc(uid);
    const partnersRef = firebase.firestore().collection("users").doc(uid).collection("partners");
    // const subscriptionRef = firebase.firestore().collection("subscriptions").doc(uid); // Subscription version: remove comments
    const surveyRef = firebase.firestore().collection("surveys").doc(uid);

    // Clearing up old listeners
    if (activeListeners.unsubscribeUserListener) activeListeners.unsubscribeUserListener()
    if (activeListeners.unsubscribePartnersListener) activeListeners.unsubscribePartnersListener()
    if (activeListeners.unsubscribeSurveyListener) activeListeners.unsubscribeSurveyListener()
    // if (activeListeners.unsubscribeSubscriptionListener) activeListeners.unsubscribeSubscriptionListener() // Subscription version: remove comments

    // Setting up new listeners for the whole app 

    // User listener
    const unsubscribeUserListener = userRef.onSnapshot(function(user) {
      // Retrieving data from Firestore
      const firestoreUser = user.data()
      if (firebaseAuthUser.emailVerified === false && firebaseAuthUser.providerData[0].providerId === 'facebook.com') {
        firestoreUser.emailVerified = true
      }
      // Setting the UI
      setActiveUser(firestoreUser)
      setSignIn(true);
      setAuthd(true);  
      // Setting web push notifications
      SubscribeUserToPush({firestore:firebase.firestore(), activeUser:firestoreUser, onSignOut})
    }, function(error) {
      setAuthd(true)
    })

    // Subscription listener // Subscription version: remove comments
    // const unsubscribeSubscriptionListener = subscriptionRef.onSnapshot(function(user) {
    //   // Setting the UI
    //   setActiveSubscription(user.data())
    // }, function(error) {
    // })

    // Survey listener
    const unsubscribeSurveyListener = surveyRef.onSnapshot(function(user) {
      // Setting the UI
      setActiveSurvey(user.data())
    }, function(error) {
    })

    // Partners listener
    const unsubscribePartnersListener = partnersRef.onSnapshot(function(querySnapshot) {
      // Retrieving data from Firestore
      let partners = [];
      querySnapshot.forEach(function(doc) {
        partners.push(doc.data());
      })
      // Setting the UI
      setActivePartners(partners)
    }, function(error) {
    });

    // Setting listeners context
    setActiveListeners({
      unsubscribeUserListener: unsubscribeUserListener,
      unsubscribeSurveyListener: unsubscribeSurveyListener,
      // unsubscribeSubscriptionListener: unsubscribeSubscriptionListener, // Subscription version: remove comments
      unsubscribePartnersListener: unsubscribePartnersListener,
    })
  }

  useEffect(() => {
    // Listen to authentication changes
    firebase.auth().onAuthStateChanged(function(firebaseAuthUser) {
      setAuthd(false) // Initializing loading UI
      if (firebaseAuthUser) {
        if(firebaseAuthUser.emailVerified || firebaseAuthUser.providerData[0].providerId === 'facebook.com') {
          // User is signed in and email is verified, set up listeners for additional information and update UI
          onSignInFromToken({firebaseAuthUser:firebaseAuthUser})
        } else {
          // User has yet to verify email, go to landing page
          setAuthd(true); // Setting the UI
        }
      } else {
        // user is signed out, go to landing page
        setSignIn(false)
        setAuthd(true); // Setting the UI
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[justRegistered])

  const authState = {
    isSignedIn: isSignedIn,
    onSignOut: onSignOut,
    setJustRegistered: setJustRegistered,
    setAuthd: setAuthd
  }

  const snackBarState = {
    snackBarOpen: snackBarOpen,
    snackBarMessage: snackBarMessage,
    setSnackBarOpen: setSnackBarOpen,
    setSnackBarMessage: setSnackBarMessage,
  }

  const userState = {
    activeUser: activeUser,
    activeListeners: activeListeners,
    // activeSubscription: activeSubscription,
    activePartners: activePartners,
    activeSurvey: activeSurvey,
    activeObjectives: activeObjectives,
    setActiveObjectives: setActiveObjectives,
    setActiveListeners: setActiveListeners
  }

  const switchAuth = () => {
    return (
      <Switch>
                  
        <Route path="/about">
          <div className={classes.root}>
          <Header/>
          <AboutRoute />
          <Footer/>    
          </div>
        </Route>

        <Route path="/howto">
          <div className={classes.root}>
          <Header/>
          <HowToRoute />
          <Footer/>    
          </div>
        </Route>
        
        {/* <Route path="/pricing">
          <div className={classes.root}>
          <Header/>
          <PricingRoute />
          <Footer/>    
          </div>
        </Route> */}

        <Route path="/termsconditions">
          <div className={classes.root}>
          <Header/>
          <TermsConditionsRoute />
          <Footer/>    
          </div>
        </Route>

        <Route path="/privacypolicy">
          <div className={classes.root}>
          <Header/>
          <PrivacyPolicyRoute />
          <Footer/>    
          </div>
        </Route>

        {switchSignedIn()}
      </Switch>
    )  
  }

  const switchSignedIn = () => {
  if (isSignedIn === true) {
    return (
      <Route path="*">
        <SignedInRoute/>
      </Route>
    );
  } else {
    return (
      <Switch>
        <Route path="/" exact>
          <div className={classes.root}>
          <Header/>
          <LandingPageRoute />
          <Footer/>  
          </div>  
        </Route>

        <Route path="/signin">
          <div className={classes.root}>
          <Header/>
          <SignInRoute />
          <Footer/>    
          </div>
        </Route>

        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
      )
    }
  };

  return (
      <authContext.Provider value={authState}>
        <userContext.Provider value={userState}>
          <snackBarContext.Provider value={snackBarState}>
            <ThemeProvider theme={theme}>
              <CssBaseline>
                {(authd && !justRegistered) ? switchAuth() : <div className={classes.progress}><CircularProgress/></div>}
                <SnackBarMessages/>
              </CssBaseline>
            </ThemeProvider>
          </snackBarContext.Provider>
        </userContext.Provider>
      </authContext.Provider>
  );
}

export default App;
