import React from 'react';

import {Questions} from '../Functions/Questions'
import TopicCardDetailedResults from './TopicCardDetailedResults';
import TopicCardSurvey from './TopicCardSurvey';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    mygrid: {
        display: 'grid',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(auto-fill, minmax(225px, 1fr))',
        },
        [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(4, minmax(225px, 1fr))',
        },

        [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(6, minmax(225px, 1fr))',
        },
        gap: '20px',
        width: '100%',
        maxWidth: '1500px',
        margin: theme.spacing(3),
        padding: theme.spacing(3),
    },
}));

function TopicsGrid({route}) {
    const classes = useStyles();

    return (
        <div className={classes.mygrid}>
            {Questions.map(topic => {
                switch(route) {
                    case "survey":
                        return <TopicCardSurvey
                        topic={topic}
                        key={topic.label}/>;
                    case "results":
                        return <TopicCardDetailedResults
                        topic={topic}
                        key={topic.label}/>;
                    default:
                        return null;
                }
})}
        </div>
    );
}

export default TopicsGrid;