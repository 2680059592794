import React, { useEffect, useState} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh',
        alignItems: 'center',
        padding: theme.spacing(3)
    },
    buttongrid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        margin: theme.spacing(1),
    },
    mybutton: {
        margin: theme.spacing(1),
    },
}));

  function PrivacyPolicyRoute () { // TODO Add second language

    const [policy, setPolicy] = useState("")

    useEffect(()=>{
        async function GetPolicy() {
            const myPolicy = await fetch('https://www.iubenda.com/api/privacy-policy/68920152').then(res => res.json());
            setPolicy(myPolicy.content);
        }

        GetPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const classes = useStyles();
    
    return (
        <Box className={classes.root}>
            {parse(policy)}
        </Box>
    );
} 

export default PrivacyPolicyRoute;