import React from 'react'

const userContext = React.createContext({
    activeUser: '',
    activeListeners: {},
    // activeSubscription: {},
    activePartners: [],
    activeObjectives: [],
    activeSurvey: {},
    setActiveObjectives: () => {},
    setActiveListeners: () => {},
    giftUser: "",
})

export default userContext;