import React from 'react'

const authContext = React.createContext({
    isSignedIn: false,
    onSignUp: () => {},
    setSnackBarOpen: () => {},
    setSnackBarMessage: () => {},
    setJustRegistered: () => {},
    setAuthd: () => {}
})

export default authContext;