import React from 'react';

import HeroUnit from '../Components/HeroUnit';

import Survey from '../Components/Pictures/questions.webp'
import Invites from '../Components/Pictures/invites.webp'
import Results from '../Components/Pictures/results.webp'
import Objectives from '../Components/Pictures/objectives.webp'

import SurveyPNG from '../Components/Pictures/questions.png'
import InvitesPNG from '../Components/Pictures/invites.png'
import ResultsPNG from '../Components/Pictures/results.png'
import ObjectivesPNG from '../Components/Pictures/objectives.png'

import LandingPNG from '../Components/Pictures/landing.jpg'
import Landing from '../Components/Pictures/landing.webp'

import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Logo from '../Components/Logo';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height:'100%',
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: 'space-evenly',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    menu: {
        display: 'flex',
        flexDirection: 'column',
        height:'100%',
        width: '100%',
        maxWidth: 500,
        flexGrow:1,
        justifyContent: 'space-evenly',
    },
    leftSidebar: {
        display: 'flex',
        flexDirection: 'column',
        '@media(minWidth: md)' : {
            height:'100%',
            width: '100%',
            marginTop: 'auto',
            marginBottom: 'auto',
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(3),
        },
        '@media(maxWidth: md)' : {
            width: 0,
            margin: 0,
        },
        alignItems: 'center',
        justifyContent: 'center',
    },
    rightSidebar: {
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent: 'space-evenly',
    '@media(minWidth: lg)' : {
            height:'auto',
            marginTop: 'auto',
            marginBottom: 'auto',
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(0),
        },
        '@media(maxWidth: lg)' : {
            width: 0,
            margin: 0,
        },
    },
}));

export default function HomePageRoute() {

    const {t} = useTranslation()

    const classes = useStyles();

    const heroUnits = [
        {
            image: Survey,
            imagePNG: SurveyPNG,
            title: t('HomePage.survey.title'),
            text: t('HomePage.survey.text'),
            action: t('HomePage.survey.action'),
            toRoute: "/survey",
            subscriptionOnly: false,
        },
        {
            image: Invites,
            imagePNG: InvitesPNG,
            title: t('HomePage.invites.title'),
            text: t('HomePage.invites.text'),
            action: t('HomePage.invites.action'),
            toRoute: "/invites",
            subscriptionOnly: false,
        },
        {
            image: Results,
            imagePNG: ResultsPNG,
            title: t('HomePage.results.title'),
            text: t('HomePage.results.text'),
            action: t('HomePage.results.action'),
            toRoute: "/AggregatedResults",
            subscriptionOnly: false,
        },
        {
            image: Objectives,
            imagePNG: ObjectivesPNG,
            title: t('HomePage.objectives.title'),
            text: t('HomePage.objectives.text'),
            action: t('HomePage.objectives.action'),
            toRoute: "/objectives",
            subscriptionOnly: true,
        },
    ]

    return (
        <div className={classes.root}>
            <div className={classes.menu}>
                {heroUnits.map((heroUnit) => (
                    <HeroUnit key={heroUnit.title} heroUnit={heroUnit}/>
                ))}
            </div>
            <div className={classes.rightSidebar}><Hidden lgDown>
                <Logo size="largest"/>
                <Box sx={{border:5, boxShadow:5, borderRadius:3, height:'auto'}}>
                    <picture>
                        <source srcSet={Landing} type="image/webp"/>
                        <img style={{width:750, height:450, boxShadow: 15, display:'flex',margin:'auto'}}
                        src={LandingPNG}
                        alt="Landing"
                        />
                    </picture>
                </Box>
            </Hidden></div>
        </div>
    );
}