import React, { useContext } from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import userContext from '../../Contexts/userContext.js';
import authContext from '../../Contexts/authContext.js';
import FeedbackForm from '../FeedbackForm.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    float: 'right'
  },
  icon: {
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      color:theme.palette.primary.main,
    },
  },
}));

export default function ProfileButton() {

  const {t} = useTranslation()

  const userState = useContext(userContext);
  const {activeUser} = userState;

  const authState = useContext(authContext);
  const {onSignOut} = authState;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {activeUser.displayName} <IconButton onClick={handleClick}>
        <AccountBoxIcon className={classes.icon} fontSize="large"/>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={onSignOut}><Button style={{width:'100%'}} variant='outlined' color='primary' size='large' >{t('General.signOut')}</Button></MenuItem>
        <MenuItem onClick={handleClose}><FeedbackForm/></MenuItem>
      </Menu>
    </Box>
  );
}