import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MailIcon from '@material-ui/icons/Mail';
import SchoolIcon from '@material-ui/icons/School';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight:theme.spacing(3),
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      color:theme.palette.secondary.main,
    },
  },
  title: {
    dispay:'flex',
    flexDirection: 'row',
    textAlign: 'right',
    justifyContent: 'right',
    flexGrow: 1,
  },
}));

export default function Navigator() {
  const classes = useStyles();
  let history = useHistory();

  function Navigate(destination) {
      history.push(destination)
  }

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.icon} color="inherit" aria-label="menu" onClick={()=>Navigate('/survey')}>
            <QuestionAnswerIcon />
          </IconButton>
          <IconButton edge="start" className={classes.icon} color="inherit" aria-label="menu" onClick={()=>Navigate('/invites')}>
            <MailIcon />
          </IconButton>
          <IconButton edge="start" className={classes.icon} color="inherit" aria-label="menu" onClick={()=>Navigate('/aggregatedResults')}>
            <AssessmentIcon />
          </IconButton>
          <IconButton edge="start" className={classes.icon} color="inherit" aria-label="menu" onClick={()=>Navigate('/objectives')}>
            <SchoolIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}