import React, {useContext} from 'react';

import TopicCardAggregatedResults from '../Components/TopicCardAggregatedResults';

import { makeStyles } from '@material-ui/core/styles';
import userContext from '../Contexts/userContext';
import { CircularProgress, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    mygrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: '20px',
        width: '100%',
        maxWidth: '1500px',
        margin: 'auto',
        padding: theme.spacing(3),
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    mycircle: {
        position: "relative",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(3),
        marginTop:theme.spacing(6)
    },
    progresstext: {
        position: "absolute",
        top:"50%",
        left:"50%",
        transform: "translate(-50%,-50%)",
    },
}));

export default function AggregatedResultsRoute() {

    const {t} = useTranslation()

    const classes = useStyles();

    const userState = useContext(userContext);
    const {activePartners, activeUser} = userState;
    const matchedPartners = activePartners.filter(partner => partner.matchingType === "matched")

    return (
        <div className={classes.root}>
            <div className={classes.mycircle}>
                <CircularProgress size={100} variant='determinate' value={activeUser.surveyProgress}></CircularProgress>
                <Typography variant="h5" color="primary" className={classes.progresstext}>{activeUser.surveyProgress}%</Typography>
            </div>
            <div>
                <Typography variant="h5" color="primary">{t('Results.completedDialog')}</Typography>
            </div>
            <div className={classes.mygrid}>
                {matchedPartners.map((partner, partnerID) => {
                    return <TopicCardAggregatedResults
                    partner={partner}
                    key={partnerID}
                    />;
                })}
            </div>
        </div>
    );
}