import React, { useContext } from 'react';

import resultsContext from '../Contexts/resultsContext';

import { PieChart } from 'react-minimal-pie-chart';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Button, CardActions, CircularProgress, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import userContext from '../Contexts/userContext';

const useStyles = makeStyles((theme)=>({
  root: {
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "space-between",
    cursor: "pointer",
    '&:hover': {
      backgroundColor: '#f7f7f7',
    },
  },
  cardcontent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  match: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  mycircle: {
    position: "relative",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progresstext: {
    position: "absolute",
    top:"50%",
    left:"50%",
    transform: "translate(-50%,-50%)",
  },
  labels: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  center: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  pie: {
    padding: theme.spacing(3),
  },
  withMargins: {
    marginRight: theme.spacing(1),
  },
}));

export default function TopicCardAggregatedResults({partner}) {

  const {t} = useTranslation()

  const theme = useTheme();
  
  const classes = useStyles();

  let history = useHistory();

  const userState = useContext(userContext)
  const {activeUser} = userState

  const resultsState = useContext(resultsContext);
  const {setResultsPartner} = resultsState;

  const {partnerType, displayName, results, surveyProgress} = partner;
  const firstName = displayName.replace(/ .*/,'')
  const {criticalQuestions, disalignedQuestions, surveyLength, match} = results[partnerType].aggregatedResults;

  let data=[];
  if (criticalQuestions !== 0) {data.push({ title: t('General.questions')+" "+t('Results.critical'), value: criticalQuestions, color: theme.palette.secondary.main })}
  if (disalignedQuestions !== 0 ) {data.push({ title: t('General.questions')+" "+t('Results.disaligned'), value: disalignedQuestions, color: theme.palette.primary.dark})}
  if ((surveyLength - disalignedQuestions - criticalQuestions) !== 0) {data.push({ title: t('General.questions')+" "+t('Results.aligned'), value: (surveyLength - disalignedQuestions - criticalQuestions), color: theme.palette.primary.light })}
  
  function onDetailsClick() {
    setResultsPartner(partner)
    history.push('/detailedResults')
  }

  const surveysComplete = (partner.surveyProgress === 100 && activeUser.surveyProgress === 100) ? true : false

  return (
    <Card className={classes.root} onClick={onDetailsClick}>
      <div>
        <CardContent className={classes.center}>
          <Typography gutterBottom variant="h6" component="h2">
            {displayName} 
          </Typography>
          <div className={classes.mycircle}>
            <CircularProgress variant="determinate" size={40} value={surveyProgress}/>  
            <Typography variant="body2" color="primary" className={classes.progresstext}>{surveyProgress}%</Typography>
          </div>          
        </CardContent>
        {surveysComplete ? <CardContent className={classes.match}>
          <Typography variant="h6" color="secondary"><Trans i18nKey="Results.match" firstName={firstName}>Tu e {{firstName}} siete un match al</Trans></Typography>
          <Typography variant="h3" color="primary">{parseInt(match*100)}%</Typography>
          {(partnerType === "partner") ? <Typography style={{marginTop:5}} variant="h6" color="primary">{t('Results.improve')}</Typography> : null}
        </CardContent> : null }
        <CardContent className={classes.pie}>
          <PieChart
            data={data}
            startAngle={-170}
            lengthAngle={160}
            totalValue={surveyLength}
            paddingAngle = {15}
            rounded = {true}
            animate = {true}
            lineWidth = {10}
            viewBoxSize={[100, 50]}
            label={({ dataEntry }) => dataEntry.value}
            labelStyle={(dataEntry) => ({
              fill: dataEntry.color,
              fontSize: '8px',
              fontFamily: 'sans-serif',
            })}
            labelPosition={70}
          />
        </CardContent>
        <CardContent className={classes.cardcontent}>
          <Typography className={classes.center} variant="body1">
            {t('HowTo.results.title')}
          </Typography>
          <Typography className={classes.labels} variant="caption">
            <span style={{color:theme.palette.secondary.main}}>{t('Results.critical')}</span> 
            <span style={{color:theme.palette.primary.dark}}>{t('Results.disaligned')}</span> 
            <span style={{color:theme.palette.primary.light}}>{t('Results.aligned')}</span>
          </Typography>
        </CardContent>
      </div>
      <CardActions className={classes.cardActions}>
        <Button color="primary" onClick={onDetailsClick}>{t('Results.details')}</Button>
      </CardActions>
    </Card>
  );
}