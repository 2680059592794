import React, { Fragment, useContext } from 'react';

import authContext from '../Contexts/authContext'

import ProfileButton from './Modals/ProfileButton'

import { Route, Switch, useHistory} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import TemporaryDrawer from './Modals/TemporaryDrawer';
import Logo from './Logo';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    transition: 'all .2s ease-in-out',
    '&:hover': {
        transform: 'scale(1.1)',
    },
    cursor:'pointer',
    padding: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  mybutton: {
    color: theme.palette.primary.contrastText,
    backgroundColor:theme.palette.secondary.main,
    '&:hover': {
      backgroundColor:theme.palette.primary.dark,
    },
  },
}));

function AppNav () {

  const {t} = useTranslation()

  const classes = useStyles();

  const authState = useContext(authContext);
  const {isSignedIn} = authState;
  let history = useHistory();

  const switchAuth = () => {
      if (isSignedIn === true) {
        return (
          <Route path="/">
            <ProfileButton/>
          </Route>
        );
      } else {
        return (
          <Switch>
              <Route path="/signin">
                  <Fragment>
                  </Fragment>
              </Route>
              <Route path="*">
                  <Fragment>
                      <Button className={classes.mybutton} onClick={()=>history.push('/signin')}>{t('General.signIn')}</Button>
                  </Fragment>
              </Route>
          </Switch>
        );
      }
    };
      
  return (
      <AppBar color='secondary' position="static">
        <Toolbar>
          <TemporaryDrawer/>
          <ul onClick={()=>history.push('/')} className={classes.logo}><Logo size="medium"/></ul>
          <div className={classes.grow}/>
          {switchAuth()}
        </Toolbar>
      </AppBar>
    );
} 

export default AppNav;