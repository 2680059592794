import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: 100,
    marginRight:theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    cursor:'pointer',
  },
}));

export default function HeroUnit({heroUnit}) {
  const classes = useStyles();

  let history = useHistory();

  function goToSection() {
    history.push(heroUnit.toRoute)
  }

  return (
    <ImageList className={classes.root} cols={1} gap={50}>
      <ImageListItem style={{height:175, backgroundSize:'contain'}} key={heroUnit.title}>
        <picture>
          <source style={{height:175, width:175, backgroundSize:'contain'}} srcSet={heroUnit.image} type="image/webp" />
          <img
          src={heroUnit.imagePNG}
          alt={heroUnit.title}
          style={{height:175, width:175, backgroundSize:'contain'}}
          />
        </picture>
        <ImageListItemBar
          title={heroUnit.title}
          subtitle={heroUnit.text}
          actionIcon={
            <Button className={classes.button} onClick={()=>goToSection()}>{heroUnit.action}</Button>
          }
        />
      </ImageListItem>
    </ImageList>
  );
}