import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    smallest: {
        display: 'flex',
    },
    medium: {
        display: 'flex',
        fontSize: theme.typography.body1.fontSize,
    },
    largest: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.h3.fontSize,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.h2.fontSize,
        },
    },
    prima: {
        fontStyle: 'italic',
        color: theme.palette.primary.light,
    },
}));

function Logo({size}) {

    const classes = useStyles();

    function LogoPicker(size) {
        switch(size) {
            case 'smallest':
                return(<Box className={classes.smallest}>parliamone<Box className={classes.prima} >prima</Box></Box>);
            case 'medium':
                return(<Box className={classes.medium}>parliamone<Box className={classes.prima} >prima</Box></Box>);
            case 'largest':
                return(<Box className={classes.largest}>parliamone<Box className={classes.prima}><b>prima</b></Box></Box>);
            default: console.error();
       };
    }
    
    return (
        LogoPicker(size)
    );
}

export default Logo;