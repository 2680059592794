import React, {useContext} from 'react';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import userContext from '../Contexts/userContext';
import snackBarContext from '../Contexts/snackBarContext';

import Button from '@material-ui/core/Button';
import { Box, makeStyles, Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from 'formik-material-ui';

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import { Select } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles( (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin:'auto',
        width: '350px',
    },
    terms: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
    },
    formControl: {
        width: '100%',
        marginBottom: theme.spacing(2)
      },
    myCheckBox: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
    },
    left: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        width: '100%'
    },
    dark: {
        color: theme.palette.error.main
    }
  }));

export default function UpdateProfile() {

    const {t} = useTranslation()

    const db = firebase.firestore();

    const classes = useStyles(); 

    const userState = useContext(userContext)
    const {activeUser} = userState
    const {uid, displayName, gender} = activeUser

    const snackBarState = useContext(snackBarContext);
    const { setSnackBarOpen, setSnackBarMessage } = snackBarState;

    return (

        <Formik
        initialValues={{ gender: gender, displayName: displayName}}
        
        validationSchema={Yup.object({
            displayName: Yup.string()
            .max(50, t('Validation.max')+" 50 "+t('Validation.characters'))
            .required(t('Validation.required')),
        })}
        
        onSubmit = { (values, {setSubmitting}) => {
    
            // Updating information on Firestore. This is automatically reflected in Authenticator through the back end
            db.collection("users").doc(uid).update({
                gender: values.gender,
                displayName: values.displayName
            })
            .then(function() {
                setSnackBarOpen(true);
                setSnackBarMessage('profileUpdated')  
            })
            .catch();

            setSubmitting(false);
        }}
        >
            <Form className={classes.root}>
                <Box className={classes.root}>

                <Typography color="primary" variant="body1" style={{marginTop:5, marginBottom:15}}>{t('Profile.update')}</Typography>

                    <FormControl className={classes.formControl}>
                        <InputLabel id="gender-simple-select-outlined-label">{t('General.gender')}</InputLabel>
                        <Field
                            component={Select}
                            name="gender"
                            inputProps={{
                            id: 'gender-simple-select-outlined-label',
                            labelId: "gender-simple-select-outlined-label"
                            }}
                        >
                            <MenuItem value="">
                                <em>{t('General.undefined')}</em>
                            </MenuItem>
                            <MenuItem value="male">{t('General.male')}</MenuItem>
                            <MenuItem value="female">{t('General.female')}</MenuItem>
                            <MenuItem value="other">{t('General.other')}</MenuItem>
                        </Field>
                    </FormControl>

                    <Field style={{width:'100%', marginTop:10, marginBottom:10}} component={TextField} label={t('General.displayName')} name="displayName" type="text"/>

                    <Button variant="contained" size="large" color="primary" type="submit" style={{marginTop: "1rem", marginBottom: "1rem", width: '100%'}}>
                    {t('General.update')}
                    </Button>
                </Box>
            </Form>
        </Formik>
  );
};