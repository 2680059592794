import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  matched: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  invitee: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark
  },
  inviter: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light
  },
  self: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  partner: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  selfTotalScore: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  partnerTotalScore: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  small: {
    fontSize: theme.typography.caption.fontSize
  },
  large: {
    fontSize: theme.typography.h4.fontSize
  },
}));

export default function LetterAvatars({initials, type, small, large}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar className={classes[type]}><span className={classes.large}>{large}</span>{initials}<span className={classes.small}>{small}</span></Avatar>
    </div>
  );
}