import React, { useContext } from 'react';

import snackBarContext from '../../Contexts/snackBarContext';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/core/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  alert: {
    width: '100%',
  },
}));

export default function SnackBarMessages() {

  const {t} = useTranslation()

  const classes = useStyles();

  const snackBarState = useContext(snackBarContext);
  const {snackBarOpen, snackBarMessage, setSnackBarOpen} = snackBarState;

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  };

  const switchsnackBarMessages = () => {
    switch (snackBarMessage) {
        case 'accountCreated':
            return (
                <Box className={classes.root}>
                    <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                        <Alert onClose={handleCloseSnackBar} severity="success" className={classes.alert}>
                        {t('SnackBar.accountCreated')}
                        </Alert>
                    </Snackbar>
                </Box>
            );
        case 'invalidCredentials':
            return (
                <Box className={classes.root}>
                    <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                        <Alert onClose={handleCloseSnackBar} severity="error" className={classes.alert}>
                        {t('SnackBar.invalidCredentials')}
                        </Alert>
                    </Snackbar>
                </Box>
            );
        case 'reauthenticate':
            return (
                <Box className={classes.root}>
                    <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                        <Alert onClose={handleCloseSnackBar} severity="error" className={classes.alert}>
                        {t('SnackBar.reauthenticate')}
                        </Alert>
                    </Snackbar>
                </Box>
            );
        case 'emailVerified':
            return (
                <Box className={classes.root}>
                    <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                        <Alert onClose={handleCloseSnackBar} severity="success" className={classes.alert}>
                        {t('SnackBar.emailVerified')}
                        </Alert>
                    </Snackbar>
                </Box>
            );
        case 'updated':
            return (
                <Box className={classes.root}>
                    <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                        <Alert onClose={handleCloseSnackBar} severity="success" className={classes.alert}>
                        {t('SnackBar.updated')}
                        </Alert>
                    </Snackbar>
                </Box>
            );
        case 'feedbackSent':
            return (
                <Box className={classes.root}>
                    <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                        <Alert onClose={handleCloseSnackBar} severity="success" className={classes.alert}>
                        {t('SnackBar.feedbackSent')}
                        </Alert>
                    </Snackbar>
                </Box>
            );
        case 'subscribed':
            return (
                <Box className={classes.root}>
                    <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                        <Alert onClose={handleCloseSnackBar} severity="success" className={classes.alert}>
                        {t('SnackBar.subscribed')}
                        </Alert>
                    </Snackbar>
                </Box>
            );
        case 'emailExists':
            return (
                <Box className={classes.root}>
                    <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                        <Alert onClose={handleCloseSnackBar} severity="error" className={classes.alert}>
                        {t('SnackBar.emailExists')}
                        </Alert>
                    </Snackbar>
                </Box>
            );
            case 'emailNotVerified':
                return (
                    <Box className={classes.root}>
                        <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                            <Alert onClose={handleCloseSnackBar} severity="error" className={classes.alert}>
                            {t('SnackBar.emailNotVerified')}
                            </Alert>
                        </Snackbar>
                    </Box>
                );
        case 'passwordModified':
            return (
                <Box className={classes.root}>
                    <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                        <Alert onClose={handleCloseSnackBar} severity="success" className={classes.alert}>
                        {t('SnackBar.passwordModified')}
                        </Alert>
                    </Snackbar>
                </Box>
            );
        case 'profileUpdated':
            return (
                <Box className={classes.root}>
                    <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                        <Alert onClose={handleCloseSnackBar} severity="success" className={classes.alert}>
                        {t('SnackBar.profileUpdated')}
                        </Alert>
                    </Snackbar>
                </Box>
            );
        case 'passwordResetRequestReceived':
            return (
                <Box className={classes.root}>
                    <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                        <Alert onClose={handleCloseSnackBar} severity="success" className={classes.alert}>
                        {t('SnackBar.passwordResetRequestReceived')}
                        </Alert>
                    </Snackbar>
                </Box>
            );
        case 'userModified':
            return (
                <Box className={classes.root}>
                    <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                        <Alert onClose={handleCloseSnackBar} severity="success" className={classes.alert}>
                        {t('SnackBar.userModified')}
                        </Alert>
                    </Snackbar>
                </Box>
            );
        case 'accountDeleted':
            return (
                <Box className={classes.root}>
                    <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                        <Alert onClose={handleCloseSnackBar} severity="success" className={classes.alert}>
                        {t('SnackBar.accountDeleted')}
                        </Alert>
                    </Snackbar>
                </Box>
            );                      
        default :
            return null;
    }
  }

  return switchsnackBarMessages()
}