import React, {useContext} from 'react';

import userContext from '../Contexts/userContext'
import snackBarContext from '../Contexts/snackBarContext'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, TextField } from '@material-ui/core';
import Rating from '@material-ui/core/Rating';

import firebase from "firebase/app";
import "firebase/firestore";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles( (theme) => ({
  root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin:'auto',
  },
  dialogRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin:'auto',
    width: '350px',
},
}));


export default function FeedbackForm() {

  const {t} = useTranslation()

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [rating, setRating] = React.useState(null);
  const [comments, setComments] = React.useState("");

  const userState = useContext(userContext);
  const { activeUser } = userState;
  const {uid} = activeUser

  const snackBarState = useContext(snackBarContext);
  const { setSnackBarOpen, setSnackBarMessage } = snackBarState;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const textError = comments.length > 1000;
  const emptyFeedback = comments==="" && rating===null
  const canSend = !textError && !emptyFeedback

  const giveFeedback = () => {

    if (canSend) {
      const gender = activeUser.gender
      firebase.firestore().collection("feedbacks").add({
        uid: uid,
        gender: gender,
        rating: rating,
        comments: comments,
        created: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(function() {
        setSnackBarOpen(true);
        setSnackBarMessage('feedbackSent')
      })
      .catch(function(error) {
      })
    }

    handleClose();
  }
 

  return (
    <div className={classes.root}>
      <Button style={{width:'100%'}} variant='contained' color='primary' size='large' onClick={handleClickOpen}>
      {t('Feedback.give')}
      </Button>
      <Dialog className={classes.dialogRoot}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('Feedback.giveTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {t('Feedback.giveDialog')}
          </DialogContentText>
          <Rating style={{marginTop: 15}}
            name="simple-controlled"
            value={rating}
            precision={0.5}
            onChange={(event, newRating) => {
              setRating(newRating);
            }}
            size="large"
          />
          <TextField style={{marginTop: 15, width:'100%'}}
            id="outlined-multiline-static"
            label={t('Feedback.comments')}
            multiline
            error={textError}
            helperText={textError ? t('Validation.max')+" 1000 "+t('Validation.characters') : null}
            rows={6}
            value={comments}
            onChange={(event) => {
              setComments(event.target.value);
            }}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          
          <Button onClick={handleClose} color="primary" variant="outlined">
            {t('General.cancel')}
          </Button>
          <Button onClick={giveFeedback} color="primary" variant="contained" autoFocus>
            {t('General.send')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}