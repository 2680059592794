import React  from 'react';

import Logo from './Logo';

import { makeStyles, Typography, Box, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        display:'flex',
        flexDirection: 'column',
        padding: theme.spacing(1, 0),
        alignItems: 'center',
        justifyContent: 'center',
        width: '100wv',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText,
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
    links: {
        display: 'flex',
        width: '300px',
        justifyContent: 'space-evenly',  
    }
}));

function Footer() {

    const {t} = useTranslation()
 
    const classes = useStyles();

    let history = useHistory();

    return(
        <footer className={classes.root}>
            <Box className={classes.footer}>
                <Typography variant='caption'>© 2021</Typography>
                &nbsp;
                <Logo size="smallest"/>
                <Typography variant='caption'>, All Rights Reserved</Typography>
            </Box>
            <Box className={classes.links}>
                <Link variant="caption" style={{cursor:"pointer"}} onClick={()=>history.push('termsconditions')}>{t('General.terms')}</Link>
                <Typography variant="caption">|</Typography>
                <Link variant="caption" style={{cursor:"pointer"}} onClick={()=>history.push('privacypolicy')}>{t('General.privacy')}</Link>
                <Typography variant="caption">|</Typography>
                <Link variant="caption" style={{cursor:"pointer"}} target="_blank" href="mailto:info@parliamoneprima.com?subject=Contact from parliamoneprima.com">{t('General.contact')}</Link>
            </Box>
        </footer>
     );
} 

export default Footer;