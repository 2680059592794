import React from 'react';

import TopicsGrid from '../Components/TopicsGrid';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
}));

export default function SurveyHomeRoute () {

    const classes = useStyles();

    return (
        <Box  className={classes.root}>
            <TopicsGrid route="survey"/>
        </Box>
    );
}
