import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import HowToCard from '../Components/HowToCard';
import { Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p:3}}>
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  center: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    margin: 'auto',
    justifyContent: 'space-between',
    height:640,
    maxWidth: 500,
    position: 'relative'
  }
}));

export default function HowToRoute() {

  const {t} = useTranslation()

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  let history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="full width tabs example"
        >
          <Tab label={t('HomePage.survey.title')} {...a11yProps(0)} />
          <Tab label={t('HomePage.invites.title')} {...a11yProps(1)} />
          <Tab label={t('HomePage.results.title')} {...a11yProps(2)} />
          <Tab label={t('HomePage.objectives.title')} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <div className={classes.center}>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <div style={{textAlign:'center', width:'100%'}}><Typography variant="h6" color="primary">{t('Drawer.howto')}</Typography></div>
          <HowToCard section="survey"/>
          <div style={{display:'flex', position:'absolute', bottom:10, right:15, flexDirection:'column', flexGrow:1, width:'100%', alignItems: 'flex-end', justifyContent:'flex-end', marginTop: 30}}><Button variant="contained" color='primary' onClick={()=>handleChange(undefined, 1)}>AVANTI</Button></div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <div style={{textAlign:'center', width:'100%'}}><Typography variant="h6" color="primary">{t('Drawer.howto')}</Typography></div>
          <HowToCard section="invites"/>
          <div style={{display:'flex', position:'absolute', bottom:10, right:15, flexDirection:'column', flexGrow:1, width:'100%', alignItems: 'flex-end', justifyContent:'flex-end', marginTop: 30}}><Button variant="contained" color='primary' onClick={()=>handleChange(undefined, 2)}>AVANTI</Button></div>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <div style={{textAlign:'center', width:'100%'}}><Typography variant="h6" color="primary">{t('Drawer.howto')}</Typography></div>
          <HowToCard section="results"/>
          <div style={{display:'flex', position:'absolute', bottom:10, right:15, flexDirection:'column', flexGrow:1, width:'100%', alignItems: 'flex-end', justifyContent:'flex-end', marginTop: 30}}><Button variant="contained" color='primary' onClick={()=>handleChange(undefined, 3)}>AVANTI</Button></div>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <div style={{textAlign:'center', width:'100%'}}><Typography variant="h6" color="primary">{t('Drawer.howto')}</Typography></div>
          <HowToCard section="objectives"/>
          <div style={{display:'flex', position:'absolute', bottom:10, right:15, flexDirection:'column', flexGrow:1, width:'100%', alignItems: 'flex-end', justifyContent:'flex-end', marginTop: 30}}><Button variant="contained" color='primary' onClick={()=>history.push('/signin')}>ACCEDI</Button></div>
        </TabPanel>
      </div>
    </div>
  );
}
